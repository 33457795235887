import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../AuthContext';
import Select from 'react-select';
import FullScreenModal from './FullScreenModal';
import logo from '../../images/logo.png'; // Path to your logo image
import TradingViewChart from './TradingViewChart';
import './CoinTable.css'

const CoinTable = () => {
    const { auth } = useContext(AuthContext);
    const [params, setParams] = useState({
        symbol: 'BTC-USDT',
        interval: '1day',
        limit: '300',
    });
    const [endpointData, setEndpointData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    // Modal controls
    const [isModalOpen, setModalOpen] = useState(false);

    const symbolOptions = [
        { value: 'AVA-USDT', label: 'AVA-USDT' },
        { value: 'FET-BTC', label: 'FET-BTC' },
        { value: 'FET-ETH', label: 'FET-ETH' },
        { value: 'ANKR-BTC', label: 'ANKR-BTC' },
        { value: 'XMR-BTC', label: 'XMR-BTC' },
        { value: 'XMR-ETH', label: 'XMR-ETH' },
        { value: 'MTV-BTC', label: 'MTV-BTC' },
        { value: 'MTV-ETH', label: 'MTV-ETH' },
        { value: 'CRO-BTC', label: 'CRO-BTC' },
        { value: 'MTV-USDT', label: 'MTV-USDT' },
        { value: 'KMD-BTC', label: 'KMD-BTC' },
        { value: 'KMD-USDT', label: 'KMD-USDT' },
        { value: 'RFOX-USDT', label: 'RFOX-USDT' },
        { value: 'TEL-USDT', label: 'TEL-USDT' },
        { value: 'TT-USDT', label: 'TT-USDT' },
        { value: 'AERGO-USDT', label: 'AERGO-USDT' },
        { value: 'XMR-USDT', label: 'XMR-USDT' },
        { value: 'TRX-KCS', label: 'TRX-KCS' },
        { value: 'ATOM-BTC', label: 'ATOM-BTC' },
        { value: 'ATOM-ETH', label: 'ATOM-ETH' },
        { value: 'ATOM-USDT', label: 'ATOM-USDT' },
        { value: 'ATOM-KCS', label: 'ATOM-KCS' },
        { value: 'FTM-USDT', label: 'FTM-USDT' },
        { value: 'VSYS-USDT', label: 'VSYS-USDT' },
        { value: 'OCEAN-BTC', label: 'OCEAN-BTC' },
        { value: 'OCEAN-ETH', label: 'OCEAN-ETH' },
        { value: 'CHR-BTC', label: 'CHR-BTC' },
        { value: 'CHR-USDT', label: 'CHR-USDT' },
        { value: 'FX-BTC', label: 'FX-BTC' },
        { value: 'FX-ETH', label: 'FX-ETH' },
        { value: 'NIM-BTC', label: 'NIM-BTC' },
        { value: 'NIM-ETH', label: 'NIM-ETH' },
        { value: 'COTI-BTC', label: 'COTI-BTC' },
        { value: 'COTI-USDT', label: 'COTI-USDT' },
        { value: 'BNB-BTC', label: 'BNB-BTC' },
        { value: 'BNB-USDT', label: 'BNB-USDT' },
        { value: 'ALGO-BTC', label: 'ALGO-BTC' },
        { value: 'ALGO-ETH', label: 'ALGO-ETH' },
        { value: 'ALGO-USDT', label: 'ALGO-USDT' },
        { value: 'XEM-BTC', label: 'XEM-BTC' },
        { value: 'XEM-USDT', label: 'XEM-USDT' },
        { value: 'XTZ-BTC', label: 'XTZ-BTC' },
        { value: 'XTZ-USDT', label: 'XTZ-USDT' },
        { value: 'ZEC-BTC', label: 'ZEC-BTC' },
        { value: 'ZEC-USDT', label: 'ZEC-USDT' },
        { value: 'ADA-BTC', label: 'ADA-BTC' },
        { value: 'ADA-USDT', label: 'ADA-USDT' },
        { value: 'BOLT-BTC', label: 'BOLT-BTC' },
        { value: 'BOLT-USDT', label: 'BOLT-USDT' },
        { value: 'ARPA-USDT', label: 'ARPA-USDT' },
        { value: 'CHZ-BTC', label: 'CHZ-BTC' },
        { value: 'CHZ-USDT', label: 'CHZ-USDT' },
        { value: 'NOIA-BTC', label: 'NOIA-BTC' },
        { value: 'NOIA-USDT', label: 'NOIA-USDT' },
        { value: 'WIN-BTC', label: 'WIN-BTC' },
        { value: 'WIN-USDT', label: 'WIN-USDT' },
        { value: 'BTT-USDT', label: 'BTT-USDT' },
        { value: 'EOSC-USDT', label: 'EOSC-USDT' },
        { value: 'ONE-BTC', label: 'ONE-BTC' },
        { value: 'ONE-USDT', label: 'ONE-USDT' },
        { value: 'TOKO-BTC', label: 'TOKO-BTC' },
        { value: 'TOKO-USDT', label: 'TOKO-USDT' },
        { value: 'LUNA-USDT', label: 'LUNA-USDT' },
        { value: 'SXP-BTC', label: 'SXP-BTC' },
        { value: 'SXP-USDT', label: 'SXP-USDT' },
        { value: 'AKRO-BTC', label: 'AKRO-BTC' },
        { value: 'AKRO-USDT', label: 'AKRO-USDT' },
        { value: 'ROOBEE-BTC', label: 'ROOBEE-BTC' },
        { value: 'WIN-TRX', label: 'WIN-TRX' },
        { value: 'AMPL-BTC', label: 'AMPL-BTC' },
        { value: 'AMPL-USDT', label: 'AMPL-USDT' },
        { value: 'DAG-USDT', label: 'DAG-USDT' },
        { value: 'KPOL-USDT', label: 'KPOL-USDT' },
        { value: 'ARX-USDT', label: 'ARX-USDT' },
        { value: 'NWC-BTC', label: 'NWC-BTC' },
        { value: 'NWC-USDT', label: 'NWC-USDT' },
        { value: 'BEPRO-BTC', label: 'BEPRO-BTC' },
        { value: 'BEPRO-USDT', label: 'BEPRO-USDT' },
        { value: 'VRA-BTC', label: 'VRA-BTC' },
        { value: 'VRA-USDT', label: 'VRA-USDT' },
        { value: 'KSM-BTC', label: 'KSM-BTC' },
        { value: 'KSM-USDT', label: 'KSM-USDT' },
        { value: 'DASH-USDT', label: 'DASH-USDT' },
        { value: 'SUTER-USDT', label: 'SUTER-USDT' },
        { value: 'SENSO-USDT', label: 'SENSO-USDT' },
        { value: 'PRE-BTC', label: 'PRE-BTC' },
        { value: 'XDB-USDT', label: 'XDB-USDT' },
        { value: 'SYLO-USDT', label: 'SYLO-USDT' },
        { value: 'SENSO-BTC', label: 'SENSO-BTC' },
        { value: 'DGB-USDT', label: 'DGB-USDT' },
        { value: 'XDB-BTC', label: 'XDB-BTC' },
        { value: 'STX-BTC', label: 'STX-BTC' },
        { value: 'STX-USDT', label: 'STX-USDT' },
        { value: 'COMP-USDT', label: 'COMP-USDT' },
        { value: 'CRO-USDT', label: 'CRO-USDT' },
        { value: 'KAI-USDT', label: 'KAI-USDT' },
        { value: 'KAI-BTC', label: 'KAI-BTC' },
        { value: 'WEST-BTC', label: 'WEST-BTC' },
        { value: 'WEST-USDT', label: 'WEST-USDT' },
        { value: 'EWT-BTC', label: 'EWT-BTC' },
        { value: 'WAVES-USDT', label: 'WAVES-USDT' },
        { value: 'WAVES-BTC', label: 'WAVES-BTC' },
        { value: 'ORN-USDT', label: 'ORN-USDT' },
        { value: 'AMPL-ETH', label: 'AMPL-ETH' },
        { value: 'MKR-USDT', label: 'MKR-USDT' },
        { value: 'SUKU-BTC', label: 'SUKU-BTC' },
        { value: 'MLK-BTC', label: 'MLK-BTC' },
        { value: 'MLK-USDT', label: 'MLK-USDT' },
        { value: 'JST-USDT', label: 'JST-USDT' },
        { value: 'KAI-ETH', label: 'KAI-ETH' },
        { value: 'SUKU-USDT', label: 'SUKU-USDT' },
        { value: 'DIA-USDT', label: 'DIA-USDT' },
        { value: 'DIA-BTC', label: 'DIA-BTC' },
        { value: 'LINK-BTC', label: 'LINK-BTC' },
        { value: 'LINK-USDT', label: 'LINK-USDT' },
        { value: 'DOT-USDT', label: 'DOT-USDT' },
        { value: 'DOT-BTC', label: 'DOT-BTC' },
        { value: 'EWT-USDT', label: 'EWT-USDT' },
        { value: 'USDJ-USDT', label: 'USDJ-USDT' },
        { value: 'CKB-BTC', label: 'CKB-BTC' },
        { value: 'CKB-USDT', label: 'CKB-USDT' },
        { value: 'UMA-USDT', label: 'UMA-USDT' },
        { value: 'ALEPH-USDT', label: 'ALEPH-USDT' },
        { value: 'VELO-USDT', label: 'VELO-USDT' },
        { value: 'SUN-USDT', label: 'SUN-USDT' },
        { value: 'YFI-USDT', label: 'YFI-USDT' },
        { value: 'UNI-USDT', label: 'UNI-USDT' },
        { value: 'UOS-USDT', label: 'UOS-USDT' },
        { value: 'UOS-BTC', label: 'UOS-BTC' },
        { value: 'NIM-USDT', label: 'NIM-USDT' },
        { value: 'DEGO-USDT', label: 'DEGO-USDT' },
        { value: 'DEGO-ETH', label: 'DEGO-ETH' },
        { value: 'FIL-USDT', label: 'FIL-USDT' },
        { value: 'AAVE-USDT', label: 'AAVE-USDT' },
        { value: 'AAVE-BTC', label: 'AAVE-BTC' },
        { value: 'AERGO-BTC', label: 'AERGO-BTC' },
        { value: 'IOST-ETH', label: 'IOST-ETH' },
        { value: 'KCS-USDT', label: 'KCS-USDT' },
        { value: 'SNX-ETH', label: 'SNX-ETH' },
        { value: 'KCS-ETH', label: 'KCS-ETH' },
        { value: 'WAN-ETH', label: 'WAN-ETH' },
        { value: 'QTUM-BTC', label: 'QTUM-BTC' },
        { value: 'MANA-BTC', label: 'MANA-BTC' },
        { value: 'TEL-BTC', label: 'TEL-BTC' },
        { value: 'XYO-ETH', label: 'XYO-ETH' },
        { value: 'VET-BTC', label: 'VET-BTC' },
        { value: 'KCS-BTC', label: 'KCS-BTC' },
        { value: 'CAPP-ETH', label: 'CAPP-ETH' },
        { value: 'ONT-BTC', label: 'ONT-BTC' },
        { value: 'DAG-ETH', label: 'DAG-ETH' },
        { value: 'WAN-BTC', label: 'WAN-BTC' },
        { value: 'KNC-ETH', label: 'KNC-ETH' },
        { value: 'LTC-USDT', label: 'LTC-USDT' },
        { value: 'BAX-ETH', label: 'BAX-ETH' },
        { value: 'BCHSV-USDT', label: 'BSV-USDT' },
        { value: 'DENT-ETH', label: 'DENT-ETH' },
        { value: 'TRAC-ETH', label: 'TRAC-ETH' },
        { value: 'ENJ-BTC', label: 'ENJ-BTC' },
        { value: 'WAX-BTC', label: 'WAXP-BTC' },
        { value: 'DGB-BTC', label: 'DGB-BTC' },
        { value: 'ELA-BTC', label: 'ELA-BTC' },
        { value: 'ZIL-BTC', label: 'ZIL-BTC' },
        { value: 'BCHSV-BTC', label: 'BSV-BTC' },
        { value: 'XLM-USDT', label: 'XLM-USDT' },
        { value: 'IOTX-ETH', label: 'IOTX-ETH' },
        { value: 'SOUL-BTC', label: 'SOUL-BTC' },
        { value: 'DOCK-BTC', label: 'DOCK-BTC' },
        { value: 'AMB-ETH', label: 'AMB-ETH' },
        { value: 'TRX-BTC', label: 'TRX-BTC' },
        { value: 'ETH-DAI', label: 'ETH-DAI' },
        { value: 'NEO-ETH', label: 'NEO-ETH' },
        { value: 'OMG-ETH', label: 'OMG-ETH' },
        { value: 'KNC-BTC', label: 'KNC-BTC' },
        { value: 'ELF-BTC', label: 'ELF-BTC' },
        { value: 'MANA-ETH', label: 'MANA-ETH' },
        { value: 'ETC-USDT', label: 'ETC-USDT' },
        { value: 'ONT-ETH', label: 'ONT-ETH' },
        { value: 'MKR-BTC', label: 'MKR-BTC' },
        { value: 'XRP-USDC', label: 'XRP-USDC' },
        { value: 'XYO-BTC', label: 'XYO-BTC' },
        { value: 'ZRX-BTC', label: 'ZRX-BTC' },
        { value: 'OLT-BTC', label: 'OLT-BTC' },
        { value: 'TRAC-BTC', label: 'TRAC-BTC' },
        { value: 'XLM-ETH', label: 'XLM-ETH' },
        { value: 'ETH-USDT', label: 'ETH-USDT' },
        { value: 'BCHSV-ETH', label: 'BSV-ETH' },
        { value: 'TRX-ETH', label: 'TRX-ETH' },
        { value: 'DOCK-ETH', label: 'DOCK-ETH' },
        { value: 'KEY-BTC', label: 'KEY-BTC' },
        { value: 'EOS-KCS', label: 'EOS-KCS' },
        { value: 'XLM-BTC', label: 'XLM-BTC' },
        { value: 'TIME-ETH', label: 'TIME-ETH' },
        { value: 'CVC-BTC', label: 'CVC-BTC' },
        { value: 'LSK-ETH', label: 'LSK-ETH' },
        { value: 'QKC-BTC', label: 'QKC-BTC' },
        { value: 'AMB-BTC', label: 'AMB-BTC' },
        { value: 'ETC-ETH', label: 'ETC-ETH' },
        { value: 'XRP-BTC', label: 'XRP-BTC' },
        { value: 'NEO-KCS', label: 'NEO-KCS' },
        { value: 'SNX-USDT', label: 'SNX-USDT' },
        { value: 'CRPT-BTC', label: 'CRPT-BTC' },
        { value: 'IOTX-BTC', label: 'IOTX-BTC' },
        { value: 'LTC-ETH', label: 'LTC-ETH' },
        { value: 'XRP-KCS', label: 'XRP-KCS' },
        { value: 'LTC-KCS', label: 'LTC-KCS' },
        { value: 'TEL-ETH', label: 'TEL-ETH' },
        { value: 'LYM-USDT', label: 'LYM-USDT' },
        { value: 'USDT-USDC', label: 'USDT-USDC' },
        { value: 'ETH-USDC', label: 'ETH-USDC' },
        { value: 'DAG-BTC', label: 'DAG-BTC' },
        { value: 'AVA-BTC', label: 'AVA-BTC' },
        { value: 'BTC-USDT', label: 'BTC-USDT' },
        { value: 'WAX-ETH', label: 'WAXP-ETH' },
        { value: 'XRP-USDT', label: 'XRP-USDT' },
        { value: 'KEY-ETH', label: 'KEY-ETH' },
        { value: 'VET-ETH', label: 'VET-ETH' },
        { value: 'FTM-BTC', label: 'FTM-BTC' },
        { value: 'USDT-DAI', label: 'USDT-DAI' },
        { value: 'ETH-BTC', label: 'ETH-BTC' },
        { value: 'MAN-BTC', label: 'MAN-BTC' },
        { value: 'TRX-USDT', label: 'TRX-USDT' },
        { value: 'BTC-DAI', label: 'BTC-DAI' },
        { value: 'ONT-USDT', label: 'ONT-USDT' },
        { value: 'DASH-ETH', label: 'DASH-ETH' },
        { value: 'BAX-BTC', label: 'BAX-BTC' },
        { value: 'AVA-ETH', label: 'AVA-ETH' },
        { value: 'LOOM-BTC', label: 'LOOM-BTC' },
        { value: 'MKR-ETH', label: 'MKR-ETH' },
        { value: 'REQ-ETH', label: 'REQ-ETH' },
        { value: 'EOS-BTC', label: 'EOS-BTC' },
        { value: 'LTC-BTC', label: 'LTC-BTC' },
        { value: 'XRP-ETH', label: 'XRP-ETH' },
        { value: 'CAPP-BTC', label: 'CAPP-BTC' },
        { value: 'FTM-ETH', label: 'FTM-ETH' },
        { value: 'DGB-ETH', label: 'DGB-ETH' },
        { value: 'VET-USDT', label: 'VET-USDT' },
        { value: 'REQ-BTC', label: 'REQ-BTC' },
        { value: 'UTK-BTC', label: 'UTK-BTC' },
        { value: 'UTK-ETH', label: 'UTK-ETH' },
        { value: 'SNX-BTC', label: 'SNX-BTC' },
        { value: 'NEO-BTC', label: 'NEO-BTC' },
        { value: 'SOUL-ETH', label: 'SOUL-ETH' },
        { value: 'NEO-USDT', label: 'NEO-USDT' },
        { value: 'OMG-BTC', label: 'OMG-BTC' },
        { value: 'TIME-BTC', label: 'TIME-BTC' },
        { value: 'ETC-BTC', label: 'ETC-BTC' },
        { value: 'DCR-BTC', label: 'DCR-BTC' },
        { value: 'BTC-USDC', label: 'BTC-USDC' },
        { value: 'ENJ-ETH', label: 'ENJ-ETH' },
        { value: 'IOST-BTC', label: 'IOST-BTC' },
        { value: 'DASH-BTC', label: 'DASH-BTC' },
        { value: 'EOS-USDT', label: 'EOS-USDT' },
        { value: 'EOS-ETH', label: 'EOS-ETH' },
        { value: 'ZIL-ETH', label: 'ZIL-ETH' },
        { value: 'OLT-USDT', label: 'OLT-USDT' },
        { value: 'GAS-BTC', label: 'GAS-BTC' },
        { value: 'BCH-BTC', label: 'BCH-BTC' },
        { value: 'VSYS-BTC', label: 'VSYS-BTC' },
        { value: 'BCH-USDT', label: 'BCH-USDT' },
        { value: 'SOLVE-BTC', label: 'SOLVE-BTC' },
        { value: 'PRE-USDT', label: 'PRE-USDT' },
        { value: 'SHR-BTC', label: 'SHR-BTC' },
        { value: 'SHR-USDT', label: 'SHR-USDT' },
        { value: 'VIDT-USDT', label: 'VIDT-USDT' },
        { value: 'ROSE-USDT', label: 'ROSE-USDT' },
        { value: 'USDC-USDT', label: 'USDC-USDT' },
        { value: 'CTI-USDT', label: 'CTI-USDT' },
        { value: 'CTI-ETH', label: 'CTI-ETH' },
        { value: 'ETH2-ETH', label: 'ksETH-ETH' },
        { value: 'PLU-USDT', label: 'PLU-USDT' },
        { value: 'GRT-USDT', label: 'GRT-USDT' },
        { value: 'CAS-BTC', label: 'CAS-BTC' },
        { value: 'CAS-USDT', label: 'CAS-USDT' },
        { value: 'REVV-USDT', label: 'REVV-USDT' },
        { value: '1INCH-USDT', label: '1INCH-USDT' },
        { value: 'API3-USDT', label: 'API3-USDT' },
        { value: 'UNFI-USDT', label: 'UNFI-USDT' },
        { value: 'HTR-USDT', label: 'HTR-USDT' },
        { value: 'FRONT-USDT', label: 'FRONT-USDT' },
        { value: 'FRONT-BTC', label: 'FRONT-BTC' },
        { value: 'WBTC-BTC', label: 'WBTC-BTC' },
        { value: 'LTC-USDC', label: 'LTC-USDC' },
        { value: 'BCH-USDC', label: 'BCH-USDC' },
        { value: 'HYDRA-USDT', label: 'HYDRA-USDT' },
        { value: 'DFI-USDT', label: 'DFI-USDT' },
        { value: 'DFI-BTC', label: 'DFI-BTC' },
        { value: 'CRV-USDT', label: 'CRV-USDT' },
        { value: 'SUSHI-USDT', label: 'SUSHI-USDT' },
        { value: 'FRM-USDT', label: 'FRM-USDT' },
        { value: 'EOS-USDC', label: 'EOS-USDC' },
        { value: 'BCHSV-USDC', label: 'BSV-USDC' },
        { value: 'ZEN-USDT', label: 'ZEN-USDT' },
        { value: 'CUDOS-USDT', label: 'CUDOS-USDT' },
        { value: 'ADA-USDC', label: 'ADA-USDC' },
        { value: 'REN-USDT', label: 'REN-USDT' },
        { value: 'LRC-USDT', label: 'LRC-USDT' },
        { value: 'LINK-USDC', label: 'LINK-USDC' },
        { value: 'KLV-USDT', label: 'KLV-USDT' },
        { value: 'KLV-BTC', label: 'KLV-BTC' },
        { value: 'THETA-USDT', label: 'THETA-USDT' },
        { value: 'QNT-USDT', label: 'QNT-USDT' },
        { value: 'BAT-USDT', label: 'BAT-USDT' },
        { value: 'DOGE-USDT', label: 'DOGE-USDT' },
        { value: 'DOGE-USDC', label: 'DOGE-USDC' },
        { value: 'DAO-USDT', label: 'DAO-USDT' },
        { value: 'TRIAS-USDT', label: 'TRIAS-USDT' },
        { value: 'TRIAS-BTC', label: 'TRIAS-BTC' },
        { value: 'DOGE-BTC', label: 'DOGE-BTC' },
        { value: 'CAKE-USDT', label: 'CAKE-USDT' },
        { value: 'ORAI-USDT', label: 'ORAI-USDT' },
        { value: 'ZEE-USDT', label: 'ZEE-USDT' },
        { value: 'MASK-USDT', label: 'MASK-USDT' },
        { value: 'KLV-TRX', label: 'KLV-TRX' },
        { value: 'IDEA-USDT', label: 'IDEA-USDT' },
        { value: 'PHA-USDT', label: 'PHA-USDT' },
        { value: 'PHA-ETH', label: 'PHA-ETH' },
        { value: 'BCH-KCS', label: 'BCH-KCS' },
        { value: 'ADA-KCS', label: 'ADA-KCS' },
        { value: 'HTR-BTC', label: 'HTR-BTC' },
        { value: 'DOT-KCS', label: 'DOT-KCS' },
        { value: 'LINK-KCS', label: 'LINK-KCS' },
        { value: 'BNB-KCS', label: 'BNB-KCS' },
        { value: 'XLM-KCS', label: 'XLM-KCS' },
        { value: 'VET-KCS', label: 'VET-KCS' },
        { value: 'DASH-KCS', label: 'DASH-KCS' },
        { value: 'UNI-KCS', label: 'UNI-KCS' },
        { value: 'AAVE-KCS', label: 'AAVE-KCS' },
        { value: 'DOGE-KCS', label: 'DOGE-KCS' },
        { value: 'ZEC-KCS', label: 'ZEC-KCS' },
        { value: 'GRT-KCS', label: 'GRT-KCS' },
        { value: 'ALGO-KCS', label: 'ALGO-KCS' },
        { value: 'EWT-KCS', label: 'EWT-KCS' },
        { value: 'GAS-USDT', label: 'GAS-USDT' },
        { value: 'AVAX-USDT', label: 'AVAX-USDT' },
        { value: 'AVAX-BTC', label: 'AVAX-BTC' },
        { value: 'KRL-BTC', label: 'KRL-BTC' },
        { value: 'KRL-USDT', label: 'KRL-USDT' },
        { value: 'POLK-USDT', label: 'POLK-USDT' },
        { value: 'POLK-BTC', label: 'POLK-BTC' },
        { value: 'ENJ-USDT', label: 'ENJ-USDT' },
        { value: 'MANA-USDT', label: 'MANA-USDT' },
        { value: 'RNDR-USDT', label: 'RNDR-USDT' },
        { value: 'RNDR-BTC', label: 'RNDR-BTC' },
        { value: 'SKEY-USDT', label: 'SKEY-USDT' },
        { value: 'LAYER-USDT', label: 'LAYER-USDT' },
        { value: 'TARA-USDT', label: 'TARA-USDT' },
        { value: 'TARA-ETH', label: 'TARA-ETH' },
        { value: 'IOST-USDT', label: 'IOST-USDT' },
        { value: 'DYP-USDT', label: 'DYP-USDT' },
        { value: 'DYP-ETH', label: 'DYP-ETH' },
        { value: 'XYM-USDT', label: 'XYM-USDT' },
        { value: 'XYM-BTC', label: 'XYM-BTC' },
        { value: 'ORBS-USDT', label: 'ORBS-USDT' },
        { value: 'ORBS-BTC', label: 'ORBS-BTC' },
        { value: 'BTC3L-USDT', label: 'BTC3L-USDT' },
        { value: 'BTC3S-USDT', label: 'BTC3S-USDT' },
        { value: 'ETH3L-USDT', label: 'ETH3L-USDT' },
        { value: 'ETH3S-USDT', label: 'ETH3S-USDT' },
        { value: 'ANKR-USDT', label: 'ANKR-USDT' },
        { value: 'DSLA-USDT', label: 'DSLA-USDT' },
        { value: 'DSLA-BTC', label: 'DSLA-BTC' },
        { value: 'SAND-USDT', label: 'SAND-USDT' },
        { value: 'VAI-USDT', label: 'VAI-USDT' },
        { value: 'XCUR-USDT', label: 'XCUR-USDT' },
        { value: 'XCUR-BTC', label: 'XCUR-BTC' },
        { value: 'FLUX-USDT', label: 'FLUX-USDT' },
        { value: 'OMG-USDT', label: 'OMG-USDT' },
        { value: 'ZIL-USDT', label: 'ZIL-USDT' },
        { value: 'DODO-USDT', label: 'DODO-USDT' },
        { value: 'MAN-USDT', label: 'MAN-USDT' },
        { value: 'BAX-USDT', label: 'BAX-USDT' },
        { value: 'BOSON-USDT', label: 'BOSON-USDT' },
        { value: 'BOSON-ETH', label: 'BOSON-ETH' },
        { value: 'PUNDIX-USDT', label: 'PUNDIX-USDT' },
        { value: 'PUNDIX-BTC', label: 'PUNDIX-BTC' },
        { value: 'WAX-USDT', label: 'WAXP-USDT' },
        { value: 'PDEX-USDT', label: 'PDEX-USDT' },
        { value: 'LABS-USDT', label: 'LABS-USDT' },
        { value: 'LABS-ETH', label: 'LABS-ETH' },
        { value: 'HAI-USDT', label: 'HAI-USDT' },
        { value: 'FORTH-USDT', label: 'FORTH-USDT' },
        { value: 'GHX-USDT', label: 'GHX-USDT' },
        { value: 'STND-USDT', label: 'STND-USDT' },
        { value: 'TOWER-USDT', label: 'TOWER-USDT' },
        { value: 'ACE-USDT', label: 'ACE-USDT' },
        { value: 'FLY-USDT', label: 'FLY-USDT' },
        { value: 'CWS-USDT', label: 'CWS-USDT' },
        { value: 'XDC-USDT', label: 'XDC-USDT' },
        { value: 'XDC-ETH', label: 'XDC-ETH' },
        { value: 'STRIKE-BTC', label: 'STRIKE-BTC' },
        { value: 'SHIB-USDT', label: 'SHIB-USDT' },
        { value: 'POLX-USDT', label: 'POLX-USDT' },
        { value: 'KDA-USDT', label: 'KDA-USDT' },
        { value: 'KDA-BTC', label: 'KDA-BTC' },
        { value: 'ICP-USDT', label: 'ICP-USDT' },
        { value: 'ICP-BTC', label: 'ICP-BTC' },
        { value: 'STC-USDT', label: 'STC-USDT' },
        { value: 'CELO-USDT', label: 'CELO-USDT' },
        { value: 'CELO-BTC', label: 'CELO-BTC' },
        { value: 'MATIC-USDT', label: 'MATIC-USDT' },
        { value: 'MATIC-BTC', label: 'MATIC-BTC' },
        { value: 'ELA-USDT', label: 'ELA-USDT' },
        { value: 'CRPT-USDT', label: 'CRPT-USDT' },
        { value: 'OGN-USDT', label: 'OGN-USDT' },
        { value: 'OGN-BTC', label: 'OGN-BTC' },
        { value: 'OUSD-USDT', label: 'OUSD-USDT' },
        { value: 'OUSD-BTC', label: 'OUSD-BTC' },
        { value: 'TLOS-USDT', label: 'TLOS-USDT' },
        { value: 'TLOS-BTC', label: 'TLOS-BTC' },
        { value: 'GLQ-USDT', label: 'GLQ-USDT' },
        { value: 'GLQ-BTC', label: 'GLQ-BTC' },
        { value: 'MXC-USDT', label: 'MXC-USDT' },
        { value: 'HOTCROSS-USDT', label: 'HOTCROSS-USDT' },
        { value: 'ADA3L-USDT', label: 'ADA3L-USDT' },
        { value: 'ADA3S-USDT', label: 'ADA3S-USDT' },
        { value: 'HYVE-USDT', label: 'HYVE-USDT' },
        { value: 'HYVE-BTC', label: 'HYVE-BTC' },
        { value: 'DAPPX-USDT', label: 'DAPPX-USDT' },
        { value: 'KONO-USDT', label: 'KONO-USDT' },
        { value: 'PRQ-USDT', label: 'PRQ-USDT' },
        { value: 'MAHA-USDT', label: 'MAHA-USDT' },
        { value: 'MAHA-BTC', label: 'MAHA-BTC' },
        { value: 'FEAR-USDT', label: 'FEAR-USDT' },
        { value: 'PYR-USDT', label: 'PYR-USDT' },
        { value: 'PYR-BTC', label: 'PYR-BTC' },
        { value: 'PROM-USDT', label: 'PROM-USDT' },
        { value: 'PROM-BTC', label: 'PROM-BTC' },
        { value: 'UNO-USDT', label: 'UNO-USDT' },
        { value: 'XCAD-USDT', label: 'XCAD-USDT' },
        { value: 'EOS3L-USDT', label: 'EOS3L-USDT' },
        { value: 'EOS3S-USDT', label: 'EOS3S-USDT' },
        { value: 'BCH3L-USDT', label: 'BCH3L-USDT' },
        { value: 'BCH3S-USDT', label: 'BCH3S-USDT' },
        { value: 'ELON-USDT', label: 'ELON-USDT' },
        { value: 'DIVI-USDT', label: 'DIVI-USDT' },
        { value: 'PDEX-BTC', label: 'PDEX-BTC' },
        { value: 'POLS-USDT', label: 'POLS-USDT' },
        { value: 'POLS-BTC', label: 'POLS-BTC' },
        { value: 'LPOOL-USDT', label: 'LPOOL-USDT' },
        { value: 'LPOOL-BTC', label: 'LPOOL-BTC' },
        { value: 'LSS-USDT', label: 'LSS-USDT' },
        { value: 'VET3L-USDT', label: 'VET3L-USDT' },
        { value: 'VET3S-USDT', label: 'VET3S-USDT' },
        { value: 'LTC3L-USDT', label: 'LTC3L-USDT' },
        { value: 'LTC3S-USDT', label: 'LTC3S-USDT' },
        { value: 'ABBC-USDT', label: 'ABBC-USDT' },
        { value: 'ABBC-BTC', label: 'ABBC-BTC' },
        { value: 'KOK-USDT', label: 'KOK-USDT' },
        { value: 'ZCX-USDT', label: 'ZCX-USDT' },
        { value: 'ZCX-BTC', label: 'ZCX-BTC' },
        { value: 'NORD-USDT', label: 'NORD-USDT' },
        { value: 'GMEE-USDT', label: 'GMEE-USDT' },
        { value: 'SFUND-USDT', label: 'SFUND-USDT' },
        { value: 'XAVA-USDT', label: 'XAVA-USDT' },
        { value: 'AI-USDT', label: 'AI-USDT' },
        { value: 'IOI-USDT', label: 'IOI-USDT' },
        { value: 'NFT-USDT', label: 'NFT-USDT' },
        { value: 'NFT-TRX', label: 'NFT-TRX' },
        { value: 'AGIX-USDT', label: 'AGIX-USDT' },
        { value: 'AGIX-BTC', label: 'AGIX-BTC' },
        { value: 'AGIX-ETH', label: 'AGIX-ETH' },
        { value: 'CQT-USDT', label: 'CQT-USDT' },
        { value: 'AIOZ-USDT', label: 'AIOZ-USDT' },
        { value: 'MARSH-USDT', label: 'MARSH-USDT' },
        { value: 'HAPI-USDT', label: 'HAPI-USDT' },
        { value: 'LPT-USDT', label: 'LPT-USDT' },
        { value: 'BOND-USDT', label: 'BOND-USDT' },
        { value: 'HAI-BTC', label: 'HAI-BTC' },
        { value: 'SOUL-USDT', label: 'SOUL-USDT' },
        { value: 'NEAR-USDT', label: 'NEAR-USDT' },
        { value: 'NEAR-BTC', label: 'NEAR-BTC' },
        { value: 'DFYN-USDT', label: 'DFYN-USDT' },
        { value: 'OOE-USDT', label: 'OOE-USDT' },
        { value: 'CFG-USDT', label: 'CFG-USDT' },
        { value: 'CFG-BTC', label: 'CFG-BTC' },
        { value: 'AXS-USDT', label: 'AXS-USDT' },
        { value: 'CLV-USDT', label: 'CLV-USDT' },
        { value: 'ROUTE-USDT', label: 'ROUTE-USDT' },
        { value: 'XDC-BTC', label: 'XDC-BTC' },
        { value: 'PMON-USDT', label: 'PMON-USDT' },
        { value: 'DPET-USDT', label: 'DPET-USDT' },
        { value: 'ERG-USDT', label: 'ERG-USDT' },
        { value: 'ERG-BTC', label: 'ERG-BTC' },
        { value: 'SOL-USDT', label: 'SOL-USDT' },
        { value: 'SLP-USDT', label: 'SLP-USDT' },
        { value: 'LITH-USDT', label: 'LITH-USDT' },
        { value: 'XCH-USDT', label: 'XCH-USDT' },
        { value: 'HAKA-USDT', label: 'HAKA-USDT' },
        { value: 'LAYER-BTC', label: 'LAYER-BTC' },
        { value: 'MTL-USDT', label: 'MTL-USDT' },
        { value: 'MTL-BTC', label: 'MTL-BTC' },
        { value: 'IOTX-USDT', label: 'IOTX-USDT' },
        { value: 'GALAX-USDT', label: 'GALA-USDT' },
        { value: 'REQ-USDT', label: 'REQ-USDT' },
        { value: 'TXA-USDT', label: 'TXA-USDT' },
        { value: 'CIRUS-USDT', label: 'CIRUS-USDT' },
        { value: 'QI-USDT', label: 'QI-USDT' },
        { value: 'QI-BTC', label: 'QI-BTC' },
        { value: 'ODDZ-USDT', label: 'ODDZ-USDT' },
        { value: 'XPR-USDT', label: 'XPR-USDT' },
        { value: 'XPR-BTC', label: 'XPR-BTC' },
        { value: 'MOVR-USDT', label: 'MOVR-USDT' },
        { value: 'MOVR-ETH', label: 'MOVR-ETH' },
        { value: 'WOO-USDT', label: 'WOO-USDT' },
        { value: 'WILD-USDT', label: 'WILD-USDT' },
        { value: 'OXT-USDT', label: 'OXT-USDT' },
        { value: 'OXT-BTC', label: 'OXT-BTC' },
        { value: 'OXT-ETH', label: 'OXT-ETH' },
        { value: 'BAL-USDT', label: 'BAL-USDT' },
        { value: 'BAL-BTC', label: 'BAL-BTC' },
        { value: 'STORJ-USDT', label: 'STORJ-USDT' },
        { value: 'STORJ-BTC', label: 'STORJ-BTC' },
        { value: 'STORJ-ETH', label: 'STORJ-ETH' },
        { value: 'YGG-USDT', label: 'YGG-USDT' },
        { value: 'SDAO-USDT', label: 'SDAO-USDT' },
        { value: 'SDAO-ETH', label: 'SDAO-ETH' },
        { value: 'XRP3L-USDT', label: 'XRP3L-USDT' },
        { value: 'XRP3S-USDT', label: 'XRP3S-USDT' },
        { value: 'SKL-USDT', label: 'SKL-USDT' },
        { value: 'SKL-BTC', label: 'SKL-BTC' },
        { value: 'NMR-USDT', label: 'NMR-USDT' },
        { value: 'NMR-BTC', label: 'NMR-BTC' },
        { value: 'TRB-USDT', label: 'TRB-USDT' },
        { value: 'TRB-BTC', label: 'TRB-BTC' },
        { value: 'DYDX-USDT', label: 'DYDX-USDT' },
        { value: 'XYO-USDT', label: 'XYO-USDT' },
        { value: 'GTC-USDT', label: 'GTC-USDT' },
        { value: 'GTC-BTC', label: 'GTC-BTC' },
        { value: 'EQX-USDT', label: 'EQX-USDT' },
        { value: 'EQX-BTC', label: 'EQX-BTC' },
        { value: 'RLC-USDT', label: 'RLC-USDT' },
        { value: 'RLC-BTC', label: 'RLC-BTC' },
        { value: 'XPRT-USDT', label: 'XPRT-USDT' },
        { value: 'EGLD-USDT', label: 'EGLD-USDT' },
        { value: 'EGLD-BTC', label: 'EGLD-BTC' },
        { value: 'HBAR-USDT', label: 'HBAR-USDT' },
        { value: 'HBAR-BTC', label: 'HBAR-BTC' },
        { value: 'DOGE3L-USDT', label: 'DOGE3L-USDT' },
        { value: 'DOGE3S-USDT', label: 'DOGE3S-USDT' },
        { value: 'FLOW-USDT', label: 'FLOW-USDT' },
        { value: 'FLOW-BTC', label: 'FLOW-BTC' },
        { value: 'NKN-USDT', label: 'NKN-USDT' },
        { value: 'NKN-BTC', label: 'NKN-BTC' },
        { value: 'PBX-USDT', label: 'PBX-USDT' },
        { value: 'SOL3L-USDT', label: 'SOL3L-USDT' },
        { value: 'SOL3S-USDT', label: 'SOL3S-USDT' },
        { value: 'XNL-USDT', label: 'XNL-USDT' },
        { value: 'SOLVE-USDT', label: 'SOLVE-USDT' },
        { value: 'DMTR-USDT', label: 'DMTR-USDT' },
        { value: 'LINK3L-USDT', label: 'LINK3L-USDT' },
        { value: 'LINK3S-USDT', label: 'LINK3S-USDT' },
        { value: 'DOT3L-USDT', label: 'DOT3L-USDT' },
        { value: 'DOT3S-USDT', label: 'DOT3S-USDT' },
        { value: 'CTSI-USDT', label: 'CTSI-USDT' },
        { value: 'CTSI-BTC', label: 'CTSI-BTC' },
        { value: 'ALICE-USDT', label: 'ALICE-USDT' },
        { value: 'ALICE-BTC', label: 'ALICE-BTC' },
        { value: 'ALICE-ETH', label: 'ALICE-ETH' },
        { value: 'OPUL-USDT', label: 'OPUL-USDT' },
        { value: 'ILV-USDT', label: 'ILV-USDT' },
        { value: 'BAND-USDT', label: 'BAND-USDT' },
        { value: 'BAND-BTC', label: 'BAND-BTC' },
        { value: 'FTT-USDT', label: 'FTT-USDT' },
        { value: 'FTT-BTC', label: 'FTT-BTC' },
        { value: 'DVPN-USDT', label: 'DVPN-USDT' },
        { value: 'SLIM-USDT', label: 'SLIM-USDT' },
        { value: 'TLM-USDT', label: 'TLM-USDT' },
        { value: 'TLM-BTC', label: 'TLM-BTC' },
        { value: 'TLM-ETH', label: 'TLM-ETH' },
        { value: 'DEXE-USDT', label: 'DEXE-USDT' },
        { value: 'DEXE-BTC', label: 'DEXE-BTC' },
        { value: 'CUDOS-BTC', label: 'CUDOS-BTC' },
        { value: 'RUNE-USDT', label: 'RUNE-USDT' },
        { value: 'RUNE-BTC', label: 'RUNE-BTC' },
        { value: 'RMRK-USDT', label: 'RMRK-USDT' },
        { value: 'C98-USDT', label: 'C98-USDT' },
        { value: 'BLOK-USDT', label: 'BLOK-USDT' },
        { value: 'ATOM3L-USDT', label: 'ATOM3L-USDT' },
        { value: 'ATOM3S-USDT', label: 'ATOM3S-USDT' },
        { value: 'UNI3L-USDT', label: 'UNI3L-USDT' },
        { value: 'UNI3S-USDT', label: 'UNI3S-USDT' },
        { value: 'PUSH-USDT', label: 'PUSH-USDT' },
        { value: 'PUSH-BTC', label: 'PUSH-BTC' },
        { value: 'AXS3L-USDT', label: 'AXS3L-USDT' },
        { value: 'AXS3S-USDT', label: 'AXS3S-USDT' },
        { value: 'FTM3L-USDT', label: 'FTM3L-USDT' },
        { value: 'FTM3S-USDT', label: 'FTM3S-USDT' },
        { value: 'FLAME-USDT', label: 'FLAME-USDT' },
        { value: 'AGLD-USDT', label: 'AGLD-USDT' },
        { value: 'NAKA-USDT', label: 'NAKA-USDT' },
        { value: 'YLD-USDT', label: 'YLD-USDT' },
        { value: 'REEF-USDT', label: 'REEF-USDT' },
        { value: 'REEF-BTC', label: 'REEF-BTC' },
        { value: 'TIDAL-USDT', label: 'TIDAL-USDT' },
        { value: 'INJ-USDT', label: 'INJ-USDT' },
        { value: 'INJ-BTC', label: 'INJ-BTC' },
        { value: 'BNB3L-USDT', label: 'BNB3L-USDT' },
        { value: 'BNB3S-USDT', label: 'BNB3S-USDT' },
        { value: 'MATIC3L-USDT', label: 'MATIC3L-USDT' },
        { value: 'MATIC3S-USDT', label: 'MATIC3S-USDT' },
        { value: 'VEGA-USDT', label: 'VEGA-USDT' },
        { value: 'VEGA-ETH', label: 'VEGA-ETH' },
        { value: 'ALPHA-USDT', label: 'ALPHA-USDT' },
        { value: 'ALPHA-BTC', label: 'ALPHA-BTC' },
        { value: 'UNO-BTC', label: 'UNO-BTC' },
        { value: 'AR-USDT', label: 'AR-USDT' },
        { value: 'AR-BTC', label: 'AR-BTC' },
        { value: 'JASMY-USDT', label: 'JASMY-USDT' },
        { value: 'PERP-USDT', label: 'PERP-USDT' },
        { value: 'PERP-BTC', label: 'PERP-BTC' },
        { value: 'SCLP-USDT', label: 'SCLP-USDT' },
        { value: 'SCLP-BTC', label: 'SCLP-BTC' },
        { value: 'SUPER-USDT', label: 'SUPER-USDT' },
        { value: 'SUPER-BTC', label: 'SUPER-BTC' },
        { value: 'CPOOL-USDT', label: 'CPOOL-USDT' },
        { value: 'HERO-USDT', label: 'HERO-USDT' },
        { value: 'AURY-USDT', label: 'AURY-USDT' },
        { value: 'SWASH-USDT', label: 'SWASH-USDT' },
        { value: 'LTO-USDT', label: 'LTO-USDT' },
        { value: 'LTO-BTC', label: 'LTO-BTC' },
        { value: 'MTRG-USDT', label: 'MTRG-USDT' },
        { value: 'DREAMS-USDT', label: 'DREAMS-USDT' },
        { value: 'SHIB-DOGE', label: 'SHIB-DOGE' },
        { value: 'QUICK-USDT', label: 'QUICK-USDT' },
        { value: 'TRU-USDT', label: 'TRU-USDT' },
        { value: 'TRU-BTC', label: 'TRU-BTC' },
        { value: 'WRX-USDT', label: 'WRX-USDT' },
        { value: 'WRX-BTC', label: 'WRX-BTC' },
        { value: 'SUSHI3L-USDT', label: 'SUSHI3L-USDT' },
        { value: 'SUSHI3S-USDT', label: 'SUSHI3S-USDT' },
        { value: 'NEAR3L-USDT', label: 'NEAR3L-USDT' },
        { value: 'NEAR3S-USDT', label: 'NEAR3S-USDT' },
        { value: 'DATA-USDT', label: 'DATA-USDT' },
        { value: 'DATA-BTC', label: 'DATA-BTC' },
        { value: 'NORD-BTC', label: 'NORD-BTC' },
        { value: 'ISP-USDT', label: 'ISP-USDT' },
        { value: 'CERE-USDT', label: 'CERE-USDT' },
        { value: 'SHILL-USDT', label: 'SHILL-USDT' },
        { value: 'ERN-USDT', label: 'ERN-USDT' },
        { value: 'ERN-BTC', label: 'ERN-BTC' },
        { value: 'FTG-USDT', label: 'FTG-USDT' },
        { value: 'PAXG-USDT', label: 'PAXG-USDT' },
        { value: 'PAXG-BTC', label: 'PAXG-BTC' },
        { value: 'AUDIO-USDT', label: 'AUDIO-USDT' },
        { value: 'AUDIO-BTC', label: 'AUDIO-BTC' },
        { value: 'ENS-USDT', label: 'ENS-USDT' },
        { value: 'AAVE3L-USDT', label: 'AAVE3L-USDT' },
        { value: 'AAVE3S-USDT', label: 'AAVE3S-USDT' },
        { value: 'SAND3L-USDT', label: 'SAND3L-USDT' },
        { value: 'SAND3S-USDT', label: 'SAND3S-USDT' },
        { value: 'XTM-USDT', label: 'XTM-USDT' },
        { value: 'MNW-USDT', label: 'MNW-USDT' },
        { value: 'FXS-USDT', label: 'FXS-USDT' },
        { value: 'FXS-BTC', label: 'FXS-BTC' },
        { value: 'ATA-USDT', label: 'ATA-USDT' },
        { value: 'ATA-BTC', label: 'ATA-BTC' },
        { value: 'VXV-USDT', label: 'VXV-USDT' },
        { value: 'LRC-BTC', label: 'LRC-BTC' },
        { value: 'LRC-ETH', label: 'LRC-ETH' },
        { value: 'DPR-USDT', label: 'DPR-USDT' },
        { value: 'CWAR-USDT', label: 'CWAR-USDT' },
        { value: 'FLUX-BTC', label: 'FLUX-BTC' },
        { value: 'PBR-USDT', label: 'PBR-USDT' },
        { value: 'TWT-USDT', label: 'TWT-USDT' },
        { value: 'TWT-BTC', label: 'TWT-BTC' },
        { value: 'OM-USDT', label: 'OM-USDT' },
        { value: 'OM-BTC', label: 'OM-BTC' },
        { value: 'ADX-USDT', label: 'ADX-USDT' },
        { value: 'AVAX3L-USDT', label: 'AVAX3L-USDT' },
        { value: 'AVAX3S-USDT', label: 'AVAX3S-USDT' },
        { value: 'MANA3L-USDT', label: 'MANA3L-USDT' },
        { value: 'MANA3S-USDT', label: 'MANA3S-USDT' },
        { value: 'GLM-USDT', label: 'GLM-USDT' },
        { value: 'GLM-BTC', label: 'GLM-BTC' },
        { value: 'NUM-USDT', label: 'NUM-USDT' },
        { value: 'VLX-USDT', label: 'VLX-USDT' },
        { value: 'VLX-BTC', label: 'VLX-BTC' },
        { value: 'TRADE-USDT', label: 'TRADE-USDT' },
        { value: 'TRADE-BTC', label: 'TRADE-BTC' },
        { value: '1EARTH-USDT', label: '1EARTH-USDT' },
        { value: 'MONI-USDT', label: 'MONI-USDT' },
        { value: 'LIKE-USDT', label: 'LIKE-USDT' },
        { value: 'LIT-USDT', label: 'LIT-USDT' },
        { value: 'LIT-BTC', label: 'LIT-BTC' },
        { value: 'KAVA-USDT', label: 'KAVA-USDT' },
        { value: 'SFP-USDT', label: 'SFP-USDT' },
        { value: 'SFP-BTC', label: 'SFP-BTC' },
        { value: 'BURGER-USDT', label: 'BURGER-USDT' },
        { value: 'CREAM-USDT', label: 'CREAM-USDT' },
        { value: 'CREAM-BTC', label: 'CREAM-BTC' },
        { value: 'RSR-USDT', label: 'RSR-USDT' },
        { value: 'RSR-BTC', label: 'RSR-BTC' },
        { value: 'IMX-USDT', label: 'IMX-USDT' },
        { value: 'GODS-USDT', label: 'GODS-USDT' },
        { value: 'KMA-USDT', label: 'KMA-USDT' },
        { value: 'POLC-USDT', label: 'POLC-USDT' },
        { value: 'XTAG-USDT', label: 'XTAG-USDT' },
        { value: 'NGC-USDT', label: 'NGC-USDT' },
        { value: 'HARD-USDT', label: 'HARD-USDT' },
        { value: 'GALAX3L-USDT', label: 'GALAX3L-USDT' },
        { value: 'GALAX3S-USDT', label: 'GALAX3S-USDT' },
        { value: 'POND-USDT', label: 'POND-USDT' },
        { value: 'POND-BTC', label: 'POND-BTC' },
        { value: 'VR-USDT', label: 'VR-USDT' },
        { value: 'EPIK-USDT', label: 'EPIK-USDT' },
        { value: 'NGL-USDT', label: 'NGL-USDT' },
        { value: 'PEL-USDT', label: 'PEL-USDT' },
        { value: 'LINA-USDT', label: 'LINA-USDT' },
        { value: 'LINA-BTC', label: 'LINA-BTC' },
        { value: 'KLAY-USDT', label: 'KLAY-USDT' },
        { value: 'KLAY-BTC', label: 'KLAY-BTC' },
        { value: 'CREDI-USDT', label: 'CREDI-USDT' },
        { value: 'TRVL-USDT', label: 'TRVL-USDT' },
        { value: 'ARKER-USDT', label: 'ARKER-USDT' },
        { value: 'BONDLY-USDT', label: 'BONDLY-USDT' },
        { value: 'BONDLY-ETH', label: 'BONDLY-ETH' },
        { value: 'XEC-USDT', label: 'XEC-USDT' },
        { value: 'HEART-USDT', label: 'HEART-USDT' },
        { value: 'HEART-BTC', label: 'HEART-BTC' },
        { value: 'UNB-USDT', label: 'UNB-USDT' },
        { value: 'GAFI-USDT', label: 'GAFI-USDT' },
        { value: 'UFO-USDT', label: 'UFO-USDT' },
        { value: 'CHMB-USDT', label: 'CHMB-USDT' },
        { value: 'GEEQ-USDT', label: 'GEEQ-USDT' },
        { value: 'PEOPLE-USDT', label: 'PEOPLE-USDT' },
        { value: 'ADS-USDT', label: 'ADS-USDT' },
        { value: 'ADS-BTC', label: 'ADS-BTC' },
        { value: 'OCEAN-USDT', label: 'OCEAN-USDT' },
        { value: 'WHALE-USDT', label: 'WHALE-USDT' },
        { value: 'TIME-USDT', label: 'TIME-USDT' },
        { value: 'CWEB-USDT', label: 'CWEB-USDT' },
        { value: 'IOTA-USDT', label: 'IOTA-USDT' },
        { value: 'IOTA-BTC', label: 'IOTA-BTC' },
        { value: 'HNT-USDT', label: 'HNT-USDT' },
        { value: 'HNT-BTC', label: 'HNT-BTC' },
        { value: 'GGG-USDT', label: 'GGG-USDT' },
        { value: 'REVU-USDT', label: 'REVU-USDT' },
        { value: 'CLH-USDT', label: 'CLH-USDT' },
        { value: 'GLMR-USDT', label: 'GLMR-USDT' },
        { value: 'GLMR-BTC', label: 'GLMR-BTC' },
        { value: 'CTC-USDT', label: 'CTC-USDT' },
        { value: 'CTC-BTC', label: 'CTC-BTC' },
        { value: 'GARI-USDT', label: 'GARI-USDT' },
        { value: 'FRR-USDT', label: 'FRR-USDT' },
        { value: 'ASTR-USDT', label: 'ASTR-USDT' },
        { value: 'ASTR-BTC', label: 'ASTR-BTC' },
        { value: 'ERTHA-USDT', label: 'ERTHA-USDT' },
        { value: 'FCON-USDT', label: 'FCON-USDT' },
        { value: 'MTS-USDT', label: 'MTS-USDT' },
        { value: 'HBB-USDT', label: 'HBB-USDT' },
        { value: 'CVX-USDT', label: 'CVX-USDT' },
        { value: 'AMP-USDT', label: 'AMP-USDT' },
        { value: 'ACT-USDT', label: 'ACT-USDT' },
        { value: 'MJT-USDT', label: 'MJT-USDT' },
        { value: 'MJT-KCS', label: 'MJT-KCS' },
        { value: 'RANKER-USDT', label: 'RANKER-USDT' },
        { value: 'WMT-USDT', label: 'WMT-USDT' },
        { value: 'XNO-USDT', label: 'XNO-USDT' },
        { value: 'XNO-BTC', label: 'XNO-BTC' },
        { value: 'MARS4-USDT', label: 'MARS4-USDT' },
        { value: 'TFUEL-USDT', label: 'TFUEL-USDT' },
        { value: 'TFUEL-BTC', label: 'TFUEL-BTC' },
        { value: 'METIS-USDT', label: 'METIS-USDT' },
        { value: 'BULL-USDT', label: 'BULL-USDT' },
        { value: 'SON-USDT', label: 'SON-USDT' },
        { value: 'MELOS-USDT', label: 'MELOS-USDT' },
        { value: 'APE-USDT', label: 'APE-USDT' },
        { value: 'GMT-USDT', label: 'GMT-USDT' },
        { value: 'BICO-USDT', label: 'BICO-USDT' },
        { value: 'STG-USDT', label: 'STG-USDT' },
        { value: 'LMR-USDT', label: 'LMR-USDT' },
        { value: 'LMR-BTC', label: 'LMR-BTC' },
        { value: 'LOKA-USDT', label: 'LOKA-USDT' },
        { value: 'JAM-USDT', label: 'JAM-USDT' },
        { value: 'JAM-ETH', label: 'JAM-ETH' },
        { value: 'BNC-USDT', label: 'BNC-USDT' },
        { value: 'LBP-USDT', label: 'LBP-USDT' },
        { value: 'CFX-USDT', label: 'CFX-USDT' },
        { value: 'LOOKS-USDT', label: 'LOOKS-USDT' },
        { value: 'XCN-USDT', label: 'XCN-USDT' },
        { value: 'XCN-BTC', label: 'XCN-BTC' },
        { value: 'UPO-USDT', label: 'UPO-USDT' },
        { value: 'CEEK-USDT', label: 'CEEK-USDT' },
        { value: 'NHCT-USDT', label: 'NHCT-USDT' },
        { value: 'FRA-USDT', label: 'FRA-USDT' },
        { value: 'VISION-USDT', label: 'VISION-USDT' },
        { value: 'ALPINE-USDT', label: 'ALPINE-USDT' },
        { value: 'WOOP-USDT', label: 'WOOP-USDT' },
        { value: 'T-USDT', label: 'T-USDT' },
        { value: 'NYM-USDT', label: 'NYM-USDT' },
        { value: 'VOXEL-USDT', label: 'VOXEL-USDT' },
        { value: 'VOXEL-ETH', label: 'VOXEL-ETH' },
        { value: 'PSTAKE-USDT', label: 'PSTAKE-USDT' },
        { value: 'SPA-USDT', label: 'SPA-USDT' },
        { value: 'SPA-ETH', label: 'SPA-ETH' },
        { value: 'DAR-USDT', label: 'DAR-USDT' },
        { value: 'DAR-BTC', label: 'DAR-BTC' },
        { value: 'MV-USDT', label: 'MV-USDT' },
        { value: 'XDEFI-USDT', label: 'XDEFI-USDT' },
        { value: 'RACA-USDT', label: 'RACA-USDT' },
        { value: 'XWG-USDT', label: 'XWG-USDT' },
        { value: 'TRVL-BTC', label: 'TRVL-BTC' },
        { value: 'SWFTC-USDT', label: 'SWFTC-USDT' },
        { value: 'BRWL-USDT', label: 'BRWL-USDT' },
        { value: 'CELR-USDT', label: 'CELR-USDT' },
        { value: 'AURORA-USDT', label: 'AURORA-USDT' },
        { value: 'KNC-USDT', label: 'KNC-USDT' },
        { value: 'PSL-USDT', label: 'PSL-USDT' },
        { value: 'OVR-USDT', label: 'OVR-USDT' },
        { value: 'SYS-USDT', label: 'SYS-USDT' },
        { value: 'SYS-BTC', label: 'SYS-BTC' },
        { value: 'BRISE-USDT', label: 'BRISE-USDT' },
        { value: 'EPX-USDT', label: 'EPX-USDT' },
        { value: 'GST-USDT', label: 'GST-USDT' },
        { value: 'GAL-USDT', label: 'GAL-USDT' },
        { value: 'BSW-USDT', label: 'BSW-USDT' },
        { value: 'FITFI-USDT', label: 'FITFI-USDT' },
        { value: 'FSN-USDT', label: 'FSN-USDT' },
        { value: 'H2O-USDT', label: 'H2O-USDT' },
        { value: 'GMM-USDT', label: 'GMM-USDT' },
        { value: 'AKT-USDT', label: 'AKT-USDT' },
        { value: 'SIN-USDT', label: 'SIN-USDT' },
        { value: 'AUSD-USDT', label: 'aUSD-USDT' },
        { value: 'BOBA-USDT', label: 'BOBA-USDT' },
        { value: 'BFC-USDT', label: 'BFC-USDT' },
        { value: 'BIFI-USDT', label: 'BIFI-USDT' },
        { value: 'MBL-USDT', label: 'MBL-USDT' },
        { value: 'DUSK-USDT', label: 'DUSK-USDT' },
        { value: 'USDD-USDT', label: 'USDD-USDT' },
        { value: 'USDD-USDC', label: 'USDD-USDC' },
        { value: 'APE-USDC', label: 'APE-USDC' },
        { value: 'AVAX-USDC', label: 'AVAX-USDC' },
        { value: 'SHIB-USDC', label: 'SHIB-USDC' },
        { value: 'TRX-USDC', label: 'TRX-USDC' },
        { value: 'NEAR-USDC', label: 'NEAR-USDC' },
        { value: 'MATIC-USDC', label: 'MATIC-USDC' },
        { value: 'FTM-USDC', label: 'FTM-USDC' },
        { value: 'ZIL-USDC', label: 'ZIL-USDC' },
        { value: 'SOL-USDC', label: 'SOL-USDC' },
        { value: 'MLS-USDT', label: 'MLS-USDT' },
        { value: 'ACH-USDT', label: 'ACH-USDT' },
        { value: 'SCRT-USDT', label: 'SCRT-USDT' },
        { value: 'SCRT-BTC', label: 'SCRT-BTC' },
        { value: 'APE3L-USDT', label: 'APE3L-USDT' },
        { value: 'APE3S-USDT', label: 'APE3S-USDT' },
        { value: 'STORE-USDT', label: 'STORE-USDT' },
        { value: 'STORE-ETH', label: 'STORE-ETH' },
        { value: 'GMT3L-USDT', label: 'GMT3L-USDT' },
        { value: 'GMT3S-USDT', label: 'GMT3S-USDT' },
        { value: 'CCD-USDT', label: 'CCD-USDT' },
        { value: 'DOSE-USDC', label: 'DOSE-USDC' },
        { value: 'LUNC-USDT', label: 'LUNC-USDT' },
        { value: 'LUNC-USDC', label: 'LUNC-USDC' },
        { value: 'USTC-USDT', label: 'USTC-USDT' },
        { value: 'USTC-USDC', label: 'USTC-USDC' },
        { value: 'GMT-USDC', label: 'GMT-USDC' },
        { value: 'VRA-USDC', label: 'VRA-USDC' },
        { value: 'DOT-USDC', label: 'DOT-USDC' },
        { value: 'RUNE-USDC', label: 'RUNE-USDC' },
        { value: 'ATOM-USDC', label: 'ATOM-USDC' },
        { value: 'BNB-USDC', label: 'BNB-USDC' },
        { value: 'JASMY-USDC', label: 'JASMY-USDC' },
        { value: 'KCS-USDC', label: 'KCS-USDC' },
        { value: 'KDA-USDC', label: 'KDA-USDC' },
        { value: 'ALGO-USDC', label: 'ALGO-USDC' },
        { value: 'LUNA-USDC', label: 'LUNA-USDC' },
        { value: 'OP-USDT', label: 'OP-USDT' },
        { value: 'OP-USDC', label: 'OP-USDC' },
        { value: 'JASMY3L-USDT', label: 'JASMY3L-USDT' },
        { value: 'JASMY3S-USDT', label: 'JASMY3S-USDT' },
        { value: 'EVER-USDT', label: 'EVER-USDT' },
        { value: 'ICX-USDT', label: 'ICX-USDT' },
        { value: 'ICX-ETH', label: 'ICX-ETH' },
        { value: 'BTC-BRL', label: 'BTC-BRL' },
        { value: 'ETH-BRL', label: 'ETH-BRL' },
        { value: 'USDT-BRL', label: 'USDT-BRL' },
        { value: 'WELL-USDT', label: 'WELL-USDT' },
        { value: 'FORT-USDT', label: 'FORT-USDT' },
        { value: 'USDP-USDT', label: 'USDP-USDT' },
        { value: 'USDD-TRX', label: 'USDD-TRX' },
        { value: 'BTC-EUR', label: 'BTC-EUR' },
        { value: 'ETH-EUR', label: 'ETH-EUR' },
        { value: 'USDT-EUR', label: 'USDT-EUR' },
        { value: 'CSPR-USDT', label: 'CSPR-USDT' },
        { value: 'CSPR-ETH', label: 'CSPR-ETH' },
        { value: 'WEMIX-USDT', label: 'WEMIX-USDT' },
        { value: 'REV3L-USDT', label: 'REV3L-USDT' },
        { value: 'OLE-USDT', label: 'OLE-USDT' },
        { value: 'LDO-USDT', label: 'LDO-USDT' },
        { value: 'LDO-USDC', label: 'LDO-USDC' },
        { value: 'CULT-USDT', label: 'CULT-USDT' },
        { value: 'SWFTC-USDC', label: 'SWFTC-USDC' },
        { value: 'FIDA-USDT', label: 'FIDA-USDT' },
        { value: 'RBP-USDT', label: 'RBP-USDT' },
        { value: 'SRBP-USDT', label: 'SRBP-USDT' },
        { value: 'HIBAYC-USDT', label: 'HIBAYC-USDT' },
        { value: 'OGV-USDT', label: 'OGV-USDT' },
        { value: 'WOMBAT-USDT', label: 'WOMBAT-USDT' },
        { value: 'HIPUNKS-USDT', label: 'HIPUNKS-USDT' },
        { value: 'FT-USDT', label: 'FT-USDT' },
        { value: 'ETC-USDC', label: 'ETC-USDC' },
        { value: 'HIENS4-USDT', label: 'HIENS4-USDT' },
        { value: 'EGAME-USDT', label: 'EGAME-USDT' },
        { value: 'EGAME-BTC', label: 'EGAME-BTC' },
        { value: 'HISAND33-USDT', label: 'HISAND33-USDT' },
        { value: 'DC-USDT', label: 'DC-USDT' },
        { value: 'NEER-USDT', label: 'NEER-USDT' },
        { value: 'RVN-USDT', label: 'RVN-USDT' },
        { value: 'HIENS3-USDT', label: 'HIENS3-USDT' },
        { value: 'MC-USDT', label: 'MC-USDT' },
        { value: 'SWEAT-USDT', label: 'SWEAT-USDT' },
        { value: 'HIODBS-USDT', label: 'HIODBS-USDT' },
        { value: 'PIX-USDT', label: 'PIX-USDT' },
        { value: 'MPLX-USDT', label: 'MPLX-USDT' },
        { value: 'HIDOODLES-USDT', label: 'HIDOODLES-USDT' },
        { value: 'ETHW-USDT', label: 'ETHW-USDT' },
        { value: 'ACQ-USDT', label: 'ACQ-USDT' },
        { value: 'ACQ-USDC', label: 'ACQ-USDC' },
        { value: 'AOG-USDT', label: 'AOG-USDT' },
        { value: 'HIMAYC-USDT', label: 'HIMAYC-USDT' },
        { value: 'PUMLX-USDT', label: 'PUMLX-USDT' },
        { value: 'XETA-USDT', label: 'XETA-USDT' },
        { value: 'GEM-USDT', label: 'GEM-USDT' },
        { value: 'DERC-USDT', label: 'DERC-USDT' },
        { value: 'KICKS-USDT', label: 'KICKS-USDT' },
        { value: 'TRIBL-USDT', label: 'TRIBL-USDT' },
        { value: 'GMX-USDT', label: 'GMX-USDT' },
        { value: 'HIOD-USDT', label: 'HIOD-USDT' },
        { value: 'POKT-USDT', label: 'POKT-USDT' },
        { value: 'APT-USDT', label: 'APT-USDT' },
        { value: 'EUL-USDT', label: 'EUL-USDT' },
        { value: 'TON-USDT', label: 'TON-USDT' },
        { value: 'HIMEEBITS-USDT', label: 'HIMEEBITS-USDT' },
        { value: 'HISQUIGGLE-USDT', label: 'HISQUIGGLE-USDT' },
        { value: 'XCV-USDT', label: 'XCV-USDT' },
        { value: 'HFT-USDT', label: 'HFT-USDT' },
        { value: 'HFT-USDC', label: 'HFT-USDC' },
        { value: 'ECOX-USDT', label: 'ECOx-USDT' },
        { value: 'AMB-USDT', label: 'AMB-USDT' },
        { value: 'AZERO-USDT', label: 'AZERO-USDT' },
        { value: 'HIFIDENZA-USDT', label: 'HIFIDENZA-USDT' },
        { value: 'BEAT-USDT', label: 'BEAT-USDT' },
        { value: 'HIGAZERS-USDT', label: 'HIGAZERS-USDT' },
        { value: 'NAVI-USDT', label: 'NAVI-USDT' },
        { value: 'HIPENGUINS-USDT', label: 'HIPENGUINS-USDT' },
        { value: 'ALT-USDT', label: 'ALT-USDT' },
        { value: 'HICLONEX-USDT', label: 'HICLONEX-USDT' },
        { value: 'OAS-USDT', label: 'OAS-USDT' },
        { value: 'VEMP-USDC', label: 'VEMP-USDC' },
        { value: 'HICOOLCATS-USDT', label: 'HICOOLCATS-USDT' },
        { value: 'HIAZUKI-USDT', label: 'HIAZUKI-USDT' },
        { value: 'HIFLUF-USDT', label: 'HIFLUF-USDT' },
        { value: 'HIBIRDS-USDT', label: 'HIBIRDS-USDT' },
        { value: 'BDX-USDT', label: 'BDX-USDT' },
        { value: 'BDX-BTC', label: 'BDX-BTC' },
        { value: 'FLR-USDT', label: 'FLR-USDT' },
        { value: 'FLR-USDC', label: 'FLR-USDC' },
        { value: 'HIMFERS-USDT', label: 'HIMFERS-USDT' },
        { value: 'OSMO-USDT', label: 'OSMO-USDT' },
        { value: 'ASTRA-USDT', label: 'ASTRA-USDT' },
        { value: 'MAGIC-USDT', label: 'MAGIC-USDT' },
        { value: 'SQUAD-USDT', label: 'SQUAD-USDT' },
        { value: 'SIMP-USDT', label: 'SIMP-USDT' },
        { value: 'HIVALHALLA-USDT', label: 'HIVALHALLA-USDT' },
        { value: 'RPL-USDT', label: 'RPL-USDT' },
        { value: 'HIRENGA-USDT', label: 'HIRENGA-USDT' },
        { value: 'KING-USDT', label: 'KING-USDT' },
        { value: 'SHIB2L-USDT', label: 'SHIB2L-USDT' },
        { value: 'SHIB2S-USDT', label: 'SHIB2S-USDT' },
        { value: 'HIGH-USDT', label: 'HIGH-USDT' },
        { value: 'HIGH-ETH', label: 'HIGH-ETH' },
        { value: 'HIUNDEAD-USDT', label: 'HIUNDEAD-USDT' },
        { value: 'TRAC-USDT', label: 'TRAC-USDT' },
        { value: 'GFT-USDT', label: 'GFT-USDT' },
        { value: 'GFT-BTC', label: 'GFT-BTC' },
        { value: 'BLUR-USDT', label: 'BLUR-USDT' },
        { value: 'HIFRIENDS-USDT', label: 'HIFRIENDS-USDT' },
        { value: 'WAXL-USDT', label: 'wAXL-USDT' },
        { value: 'FLOKI-USDT', label: 'FLOKI-USDT' },
        { value: 'SSV-USDT', label: 'SSV-USDT' },
        { value: 'FLOKI-USDC', label: 'FLOKI-USDC' },
        { value: 'ACS-USDT', label: 'ACS-USDT' },
        { value: 'IGU-USDT', label: 'IGU-USDT' },
        { value: 'CSIX-USDT', label: 'CSIX-USDT' },
        { value: 'FET-USDT', label: 'FET-USDT' },
        { value: 'CSIX-ETH', label: 'CSIX-ETH' },
        { value: 'CFX2L-USDT', label: 'CFX2L-USDT' },
        { value: 'CFX2S-USDT', label: 'CFX2S-USDT' },
        { value: 'HISEALS-USDT', label: 'HISEALS-USDT' },
        { value: 'SIDUS-USDT', label: 'SIDUS-USDT' },
        { value: 'GOAL-USDT', label: 'GOAL-USDT' },
        { value: 'AIPAD-USDT', label: 'AIPAD-USDT' },
        { value: 'RDNT-USDT', label: 'RDNT-USDT' },
        { value: 'SYN-USDT', label: 'SYN-USDT' },
        { value: 'GNS-USDT', label: 'GNS-USDT' },
        { value: 'GRAIL-USDT', label: 'GRAIL-USDT' },
        { value: 'BLZ-USDT', label: 'BLZ-USDT' },
        { value: 'HIBEANZ-USDT', label: 'HIBEANZ-USDT' },
        { value: 'BTC-GBP', label: 'BTC-GBP' },
        { value: 'ETH-GBP', label: 'ETH-GBP' },
        { value: 'USDT-GBP', label: 'USDT-GBP' },
        { value: 'HALO-USDT', label: 'HALO-USDT' },
        { value: 'NXRA-USDT', label: 'NXRA-USDT' },
        { value: 'NXRA-ETH', label: 'NXRA-ETH' },
        { value: 'MINA-USDT', label: 'MINA-USDT' },
        { value: 'XRD-USDT', label: 'XRD-USDT' },
        { value: 'NOM-USDT', label: 'NOM-USDT' },
        { value: 'LQTY-USDT', label: 'LQTY-USDT' },
        { value: 'CFX-BTC', label: 'CFX-BTC' },
        { value: 'CFX-ETH', label: 'CFX-ETH' },
        { value: 'ID-USDT', label: 'ID-USDT' },
        { value: 'ARB-USDT', label: 'ARB-USDT' },
        { value: 'ARB3S-USDT', label: 'ARB3S-USDT' },
        { value: 'ARB3L-USDT', label: 'ARB3L-USDT' },
        { value: 'ID3L-USDT', label: 'ID3L-USDT' },
        { value: 'ID3S-USDT', label: 'ID3S-USDT' },
        { value: 'HMND-USDT', label: 'HMND-USDT' },
        { value: 'BTCUP-USDT', label: 'BTCUP-USDT' },
        { value: 'BTCDOWN-USDT', label: 'BTCDOWN-USDT' },
        { value: 'ETHUP-USDT', label: 'ETHUP-USDT' },
        { value: 'ETHDOWN-USDT', label: 'ETHDOWN-USDT' },
        { value: 'HIFI-USDT', label: 'HIFI-USDT' },
        { value: 'MYRIA-USDT', label: 'MYRIA-USDT' },
        { value: 'SD-USDT', label: 'SD-USDT' },
        { value: 'CGPT-USDT', label: 'CGPT-USDT' },
        { value: 'STRAX-USDT', label: 'STRAX-USDT' },
        { value: 'KAGI-USDT', label: 'kAGI-USDT' },
        { value: 'DYDXUP-USDT', label: 'DYDXUP-USDT' },
        { value: 'DYDXDOWN-USDT', label: 'DYDXDOWN-USDT' },
        { value: 'OTK-USDT', label: 'OTK-USDT' },
        { value: 'RNDRUP-USDT', label: 'RNDRUP-USDT' },
        { value: 'RNDRDOWN-USDT', label: 'RNDRDOWN-USDT' },
        { value: 'BABYDOGE-USDT', label: 'BABYDOGE-USDT' },
        { value: 'PZP-USDT', label: 'PZP-USDT' },
        { value: 'INJDOWN-USDT', label: 'INJDOWN-USDT' },
        { value: 'INJUP-USDT', label: 'INJUP-USDT' },
        { value: 'LOCUS-USDT', label: 'LOCUS-USDT' },
        { value: 'IRON-USDT', label: 'IRON-USDT' },
        { value: 'ZPAY-USDT', label: 'ZPAY-USDT' },
        { value: 'IZI-USDT', label: 'IZI-USDT' },
        { value: 'SUI-USDT', label: 'SUI-USDT' },
        { value: 'SUI3S-USDT', label: 'SUI3S-USDT' },
        { value: 'SUI3L-USDT', label: 'SUI3L-USDT' },
        { value: 'KAS-USDT', label: 'KAS-USDT' },
        { value: 'PEPE-USDT', label: 'PEPE-USDT' },
        { value: 'PEPEUP-USDT', label: 'PEPEUP-USDT' },
        { value: 'PEPEDOWN-USDT', label: 'PEPEDOWN-USDT' },
        { value: 'CETUS-USDT', label: 'CETUS-USDT' },
        { value: 'SUIP-USDT', label: 'SUIP-USDT' },
        { value: 'KARATE-USDT', label: 'KARATE-USDT' },
        { value: 'WLKN-USDT', label: 'WLKN-USDT' },
        { value: 'TURBOS-USDT', label: 'TURBOS-USDT' },
        { value: 'LUNCUP-USDT', label: 'LUNCUP-USDT' },
        { value: 'LUNCDOWN-USDT', label: 'LUNCDOWN-USDT' },
        { value: 'SUIA-USDT', label: 'SUIA-USDT' },
        { value: 'LMWR-USDT', label: 'LMWR-USDT' },
        { value: 'BOB-USDT', label: 'BOB-USDT' },
        { value: 'LADYS-USDT', label: 'LADYS-USDT' },
        { value: 'RFD-USDT', label: 'RFD-USDT' },
        { value: 'TENET-USDT', label: 'TENET-USDT' },
        { value: 'HIBAKC-USDT', label: 'HIBAKC-USDT' },
        { value: 'VERSE-USDT', label: 'VERSE-USDT' },
        { value: 'OBI-USDT', label: 'OBI-USDT' },
        { value: 'INFRA-USDT', label: 'INFRA-USDT' },
        { value: 'TSUGT-USDT', label: 'TSUGT-USDT' },
        { value: 'EDU-USDT', label: 'EDU-USDT' },
        { value: 'ORDI-USDT', label: 'ORDI-USDT' },
        { value: 'LAI-USDT', label: 'LAI-USDT' },
        { value: 'VOLT-USDT', label: 'VOLT-USDT' },
        { value: 'TOMI-USDT', label: 'TOMI-USDT' },
        { value: 'COMBO-USDT', label: 'COMBO-USDT' },
        { value: 'CANDY-USDT', label: 'CANDY-USDT' },
        { value: 'PIP-USDT', label: 'PIP-USDT' },
        { value: 'VCORE-USDT', label: 'VCORE-USDT' },
        { value: 'MMM-USDT', label: 'MMM-USDT' },
        { value: 'MAV-USDT', label: 'MAV-USDT' },
        { value: 'XEN-USDT', label: 'XEN-USDT' },
        { value: 'LBR-USDT', label: 'LBR-USDT' },
        { value: 'PENDLE-USDT', label: 'PENDLE-USDT' },
        { value: 'EGO-USDT', label: 'EGO-USDT' },
        { value: 'PEPE2-USDT', label: 'PEPE2-USDT' },
        { value: 'MM-USDT', label: 'MM-USDT' },
        { value: 'DCK-USDT', label: 'DCK-USDT' },
        { value: 'LYX-USDT', label: 'LYX-USDT' },
        { value: 'LYX-ETH', label: 'LYX-ETH' },
        { value: 'WLD-USDT', label: 'WLD-USDT' },
        { value: 'WLDUP-USDT', label: 'WLDUP-USDT' },
        { value: 'WLDDOWN-USDT', label: 'WLDDOWN-USDT' },
        { value: 'AIEPK-USDT', label: 'AIEPK-USDT' },
        { value: 'SEI-USDT', label: 'SEI-USDT' },
        { value: 'UNFIUP-USDT', label: 'UNFIUP-USDT' },
        { value: 'UNFIDOWN-USDT', label: 'UNFIDOWN-USDT' },
        { value: 'PYUSD-USDT', label: 'PYUSD-USDT' },
        { value: 'ISLM-USDT', label: 'ISLM-USDT' },
        { value: 'TRBUP-USDT', label: 'TRBUP-USDT' },
        { value: 'TRBDOWN-USDT', label: 'TRBDOWN-USDT' },
        { value: 'BIGTIME-USDT', label: 'BIGTIME-USDT' },
        { value: 'NTRN-USDT', label: 'NTRN-USDT' },
        { value: 'ARKM-USDT', label: 'ARKM-USDT' },
        { value: 'VRAUP-USDT', label: 'VRAUP-USDT' },
        { value: 'VRADOWN-USDT', label: 'VRADOWN-USDT' },
        { value: 'OFN-USDT', label: 'OFN-USDT' },
        { value: 'ZELIX-USDT', label: 'ZELIX-USDT' },
        { value: 'TIA-USDT', label: 'TIA-USDT' },
        { value: 'KLUB-USDT', label: 'KLUB-USDT' },
        { value: 'CYBER-USDT', label: 'CYBER-USDT' },
        { value: 'MEME-USDT', label: 'MEME-USDT' },
        { value: 'TOKEN-USDT', label: 'TOKEN-USDT' },
        { value: 'SHRAP-USDT', label: 'SHRAP-USDT' },
        { value: 'ORDIUP-USDT', label: 'ORDIUP-USDT' },
        { value: 'ORDIDOWN-USDT', label: 'ORDIDOWN-USDT' },
        { value: 'POL-USDT', label: 'POL-USDT' },
        { value: 'SATS-USDT', label: 'SATS-USDT' },
        { value: 'RPK-USDT', label: 'RPK-USDT' },
        { value: 'ATEM-USDT', label: 'ATEM-USDT' },
        { value: 'ATOR-USDT', label: 'ATOR-USDT' },
        { value: 'PYTH-USDT', label: 'PYTH-USDT' },
        { value: 'RATS-USDT', label: 'RATS-USDT' },
        { value: 'VRTX-USDT', label: 'VRTX-USDT' },
        { value: 'ROOT-USDT', label: 'ROOT-USDT' },
        { value: 'WBTC-USDT', label: 'WBTC-USDT' },
        { value: 'LOOM-USDT', label: 'LOOM-USDT' },
        { value: 'ELF-USDT', label: 'ELF-USDT' },
        { value: 'DCR-USDT', label: 'DCR-USDT' },
        { value: 'ZRX-USDT', label: 'ZRX-USDT' },
        { value: 'KASUP-USDT', label: 'KASUP-USDT' },
        { value: 'KASDOWN-USDT', label: 'KASDOWN-USDT' },
        { value: 'TIAUP-USDT', label: 'TIAUP-USDT' },
        { value: 'TIADOWN-USDT', label: 'TIADOWN-USDT' },
        { value: 'PYTHUP-USDT', label: 'PYTHUP-USDT' },
        { value: 'PYTHDOWN-USDT', label: 'PYTHDOWN-USDT' },
        { value: 'FLIP-USDT', label: 'FLIP-USDT' },
        { value: 'UQC-USDT', label: 'UQC-USDT' },
        { value: 'LSK-USDT', label: 'LSK-USDT' },
        { value: 'DENT-USDT', label: 'DENT-USDT' },
        { value: 'QKC-USDT', label: 'QKC-USDT' },
        { value: 'BONK-USDT', label: 'BONK-USDT' },
        { value: 'MIND-USDT', label: 'MIND-USDT' },
        { value: 'MNT-USDT', label: 'MNT-USDT' },
        { value: 'WORK-USDT', label: 'WORK-USDT' },
        { value: 'INSP-USDT', label: 'INSP-USDT' },
        { value: 'AUCTION-USDT', label: 'AUCTION-USDT' },
        { value: 'JTO-USDT', label: 'JTO-USDT' },
        { value: 'BAKEUP-USDT', label: 'BAKEUP-USDT' },
        { value: 'BAKEDOWN-USDT', label: 'BAKEDOWN-USDT' },
        { value: 'UTK-USDT', label: 'UTK-USDT' },
        { value: 'SNS-USDT', label: 'SNS-USDT' },
        { value: 'DOVI-USDT', label: 'DOVI-USDT' },
        { value: 'SEAM-USDT', label: 'SEAM-USDT' },
        { value: 'FINC-USDT', label: 'FINC-USDT' },
        { value: 'VANRY-USDT', label: 'VANRY-USDT' },
        { value: 'GTT-USDT', label: 'GTT-USDT' },
        { value: 'MNDE-USDT', label: 'MNDE-USDT' },
        { value: 'COQ-USDT', label: 'COQ-USDT' },
        { value: 'IRL-USDT', label: 'IRL-USDT' },
        { value: 'SOLS-USDT', label: 'SOLS-USDT' },
        { value: 'POLYX-USDT', label: 'POLYX-USDT' },
        { value: 'SCPT-USDT', label: 'SCPT-USDT' },
        { value: 'TAO-USDT', label: 'TAO-USDT' },
        { value: 'TURT-USDT', label: 'TURT-USDT' },
        { value: 'BIIS-USDT', label: 'BIIS-USDT' },
        { value: 'ARTY-USDT', label: 'ARTY-USDT' },
        { value: 'GRAPE-USDT', label: 'GRAPE-USDT' },
        { value: 'MUBI-USDT', label: 'MUBI-USDT' },
        { value: 'AA-USDT', label: 'AA-USDT' },
        { value: 'ZOOA-USDT', label: 'ZOOA-USDT' },
        { value: 'ANALOS-USDT', label: 'ANALOS-USDT' },
        { value: 'SEIUP-USDT', label: 'SEIUP-USDT' },
        { value: 'SEIDOWN-USDT', label: 'SEIDOWN-USDT' },
        { value: 'OPUP-USDT', label: 'OPUP-USDT' },
        { value: 'OPDOWN-USDT', label: 'OPDOWN-USDT' },
        { value: 'MYRO-USDT', label: 'MYRO-USDT' },
        { value: 'SILLY-USDT', label: 'SILLY-USDT' },
        { value: 'MOBILE-USDT', label: 'MOBILE-USDT' },
        { value: 'ROUP-USDT', label: 'ROUP-USDT' },
        { value: 'OOFP-USDT', label: 'OOFP-USDT' },
        { value: '1CAT-USDT', label: '1CAT-USDT' },
        { value: 'RAY-USDT', label: 'RAY-USDT' },
        { value: 'ALEX-USDT', label: 'ALEX-USDT' },
        { value: 'ZKF-USDT', label: 'ZKF-USDT' },
        { value: 'PORT3-USDT', label: 'PORT3-USDT' },
        { value: 'XAI-USDT', label: 'XAI-USDT' },
        { value: 'NFP-USDT', label: 'NFP-USDT' },
        { value: 'ORCA-USDT', label: 'ORCA-USDT' },
        { value: 'KACE-USDT', label: 'kACE-USDT' },
        { value: 'APP-USDT', label: 'APP-USDT' },
        { value: 'NEON-USDT', label: 'NEON-USDT' },
        { value: 'ISSP-USDT', label: 'ISSP-USDT' },
        { value: 'BIDP-USDT', label: 'BIDP-USDT' },
        { value: 'HON-USDT', label: 'HON-USDT' },
        { value: 'TUNE-USDT', label: 'TUNE-USDT' },
        { value: 'ETGM-USDT', label: 'ETGM-USDT' },
        { value: 'MANTA-USDT', label: 'MANTA-USDT' },
        { value: 'ONDO-USDT', label: 'ONDO-USDT' },
        { value: 'WIF-USDT', label: 'WIF-USDT' },
        { value: 'SAROS-USDT', label: 'SAROS-USDT' },
        { value: 'AMU-USDT', label: 'AMU-USDT' },
        { value: 'GTAI-USDT', label: 'GTAI-USDT' },
        { value: 'KALT-USDT', label: 'kALT-USDT' },
        { value: 'DMAIL-USDT', label: 'DMAIL-USDT' },
        { value: 'AFG-USDT', label: 'AFG-USDT' },
        { value: 'WEN-USDT', label: 'WEN-USDT' },
        { value: 'ZETA-USDT', label: 'ZETA-USDT' },
        { value: 'DEFI-USDT', label: 'DEFI-USDT' },
        { value: 'PMG-USDT', label: 'PMG-USDT' },
        { value: 'JUP-USDT', label: 'JUP-USDT' },
        { value: 'FIRE-USDT', label: 'FIRE-USDT' },
        { value: 'STRIKE-USDT', label: 'STRIKE-USDT' },
        { value: 'MXM-USDT', label: 'MXM-USDT' },
        { value: 'BMX-USDT', label: 'BMX-USDT' },
        { value: 'MAVIA-USDT', label: 'MAVIA-USDT' },
        { value: 'FORWARD-USDT', label: 'FORWARD-USDT' },
        { value: 'DYM-USDT', label: 'DYM-USDT' },
        { value: 'NETVR-USDT', label: 'NETVR-USDT' },
        { value: 'NAVX-USDT', label: 'NAVX-USDT' },
        { value: 'LENDS-USDT', label: 'LENDS-USDT' },
        { value: 'PIXEL-USDT', label: 'PIXEL-USDT' },
        { value: 'STRK-USDT', label: 'STRK-USDT' },
        { value: 'BCUT-USDT', label: 'BCUT-USDT' },
        { value: 'TADA-USDT', label: 'TADA-USDT' },
        { value: 'SLN-USDT', label: 'SLN-USDT' },
        { value: 'PANDORA-USDT', label: 'PANDORA-USDT' },
        { value: 'DECHAT-USDT', label: 'DECHAT-USDT' },
        { value: 'SAVM-USDT', label: 'SAVM-USDT' },
        { value: 'BBL-USDT', label: 'BBL-USDT' },
        { value: 'ICE-USDT', label: 'ICE-USDT' },
        { value: 'AGIXUP-USDT', label: 'AGIXUP-USDT' },
        { value: 'AGIXDOWN-USDT', label: 'AGIXDOWN-USDT' },
        { value: 'PORTAL-USDT', label: 'PORTAL-USDT' },
        { value: 'QORPO-USDT', label: 'QORPO-USDT' },
        { value: 'HGP-USDT', label: 'HGP-USDT' },
        { value: 'STAMP-USDT', label: 'STAMP-USDT' },
        { value: 'VOYA-USDT', label: 'VOYA-USDT' },
        { value: 'KAS-BTC', label: 'KAS-BTC' },
        { value: 'AEG-USDT', label: 'AEG-USDT' },
        { value: 'TUSD-USDT', label: 'TUSD-USDT' },
        { value: 'SCA-USDT', label: 'SCA-USDT' },
        { value: 'PATEX-USDT', label: 'PATEX-USDT' },
        { value: 'NIBI-USDT', label: 'NIBI-USDT' },
        { value: 'ISME-USDT', label: 'ISME-USDT' },
        { value: 'KNGL-USDT', label: 'kNGL-USDT' },
        { value: 'AEVO-USDT', label: 'AEVO-USDT' },
        { value: 'ZEND-USDT', label: 'ZEND-USDT' },
        { value: 'HTX-USDT', label: 'HTX-USDT' },
        { value: 'UTXO-USDT', label: 'UTXO-USDT' },
        { value: 'AITECH-USDT', label: 'AITECH-USDT' },
        { value: 'BOME-USDT', label: 'BOME-USDT' },
        { value: 'ETHFI-USDT', label: 'ETHFI-USDT' },
        { value: 'MPC-USDT', label: 'MPC-USDT' },
        { value: 'ZK-USDT', label: 'ZK-USDT' },
        { value: 'BEFI-USDT', label: 'BEFI-USDT' },
        { value: 'GMRX-USDT', label: 'GMRX-USDT' },
        { value: 'BRAWL-USDT', label: 'BRAWL-USDT' },
        { value: 'SMOLE-USDT', label: 'SMOLE-USDT' },
        { value: 'VENOM-USDT', label: 'VENOM-USDT' },
        { value: 'ENA-USDT', label: 'ENA-USDT' },
        { value: 'USDE-USDT', label: 'USDe-USDT' },
        { value: 'W-USDT', label: 'W-USDT' },
        { value: 'MEW-USDT', label: 'MEW-USDT' },
        { value: 'ZEUS-USDT', label: 'ZEUS-USDT' },
        { value: 'AERO-USDT', label: 'AERO-USDT' },
        { value: 'TRUF-USDT', label: 'TRUF-USDT' },
        { value: 'G3-USDT', label: 'G3-USDT' },
        { value: 'TNSR-USDT', label: 'TNSR-USDT' },
        { value: 'ESE-USDT', label: 'ESE-USDT' },
        { value: 'BLOCK-USDT', label: 'BLOCK-USDT' },
        { value: 'MASA-USDT', label: 'MASA-USDT' },
        { value: 'SQR-USDT', label: 'SQR-USDT' },
        { value: 'FOXY-USDT', label: 'FOXY-USDT' },
        { value: 'ARC-USDT', label: 'ARC-USDT' },
        { value: 'OMNI-USDT', label: 'OMNI-USDT' },
        { value: 'PRCL-USDT', label: 'PRCL-USDT' },
        { value: 'MAPO-USDT', label: 'MAPO-USDT' },
        { value: 'MERL-USDT', label: 'MERL-USDT' },
        { value: 'DEGEN-USDT', label: 'DEGEN-USDT' },
        { value: 'KARRAT-USDT', label: 'KARRAT-USDT' },
        { value: 'VINU-USDT', label: 'VINU-USDT' },
        { value: 'SPOT-USDT', label: 'SPOT-USDT' },
        { value: 'LL-USDT', label: 'LL-USDT' },
        { value: 'PBUX-USDT', label: 'PBUX-USDT' },
        { value: 'SAFE-USDT', label: 'SAFE-USDT' },
        { value: 'HIP-USDT', label: 'HIP-USDT' },
        { value: 'MSN-USDT', label: 'MSN-USDT' },
        { value: 'RIO-USDT', label: 'RIO-USDT' },
        { value: 'REZ-USDT', label: 'REZ-USDT' },
        { value: 'KMNO-USDT', label: 'KMNO-USDT' },


    ];
    const intervalOptions = [
        { value: '1min', label: '1 minute' },
        { value: '3min', label: '3 minutes' },
        { value: '5min', label: '5 minutes' },
        { value: '15min', label: '15 minutes' },
        { value: '30min', label: '30 minutes' },
        { value: '1hour', label: '1 hour' },
        { value: '2hour', label: '2 hours' },
        { value: '4hour', label: '4 hours' },
        { value: '6hour', label: '6 hours' },
        { value: '8hour', label: '8 hours' },
        { value: '12hour', label: '12 hours' },
        { value: '1day', label: '1 day' },
        { value: '1week', label: '1 week' },
    ];
    const limitOptions = [
        { value: '100', label: '100' },
        { value: '200', label: '200' },
        { value: '300', label: '300' },
        { value: '500', label: '500' },
    ];

    const endpoints = [
        { url: '/rsi-v2' },
        { url: '/ema-v2' },
        { url: '/sma-v2' },
        { url: '/wma-v2' },
        { url: '/dema-v2' },
        { url: '/tema-v2' },
        { url: '/kama-v2' },
        { url: '/vwap-v2' },
        { url: '/candles-type' },
        // Add more endpoints as needed
    ];
    const baseUrl = 'https://rest.indicatorsuccessrate.com/api/indicators';

    const handleInputChange = (selectedOption, actionMeta) => {
        setParams((prevParams) => ({
            ...prevParams,
            [actionMeta.name]: selectedOption.value,
        }));
    };

    const parseData = (endpointUrl, data) => {
        const endpoint = endpoints.find((ep) => endpointUrl.includes(ep.url));
        if (!endpoint) return {};

        let result = {};

        if (endpoint.url === '/rsi-v2') {
            // For RSI endpoint, filter out only the 'rsi' value from the last item
            const lastItem = data[data.length - 1];
            result = {
                // timestamp: lastItem.timestamp,
                'Open': lastItem.open.toLocaleString('en-US', { minimumFractionDigits: 8, maximumFractionDigits: 8 }),
                'Close': lastItem.close.toLocaleString('en-US', { minimumFractionDigits: 8, maximumFractionDigits: 8 }),
                'High': lastItem.high.toLocaleString('en-US', { minimumFractionDigits: 8, maximumFractionDigits: 8 }),
                'Low': lastItem.low.toLocaleString('en-US', { minimumFractionDigits: 8, maximumFractionDigits: 8 }),
                'Volume': lastItem.volume.toLocaleString('en-US', { minimumFractionDigits: 8, maximumFractionDigits: 8 }),
                'RSI': lastItem.rsi.toLocaleString('en-US', { minimumFractionDigits: 8, maximumFractionDigits: 8 }),
            };
        } else if (endpoint.url === '/ema-v2') {
            // For other endpoints, parse the data as needed from the last item
            const lastItem = data[data.length - 1];
            result = {
                atr: lastItem.atr.toLocaleString('en-US', { minimumFractionDigits: 8, maximumFractionDigits: 8 }),
                ema5: lastItem.ema_5.toLocaleString('en-US', { minimumFractionDigits: 8, maximumFractionDigits: 8 }),
                ema10: lastItem.ema_10.toLocaleString('en-US', { minimumFractionDigits: 8, maximumFractionDigits: 8 }),
                ema12: lastItem.ema_12.toLocaleString('en-US', { minimumFractionDigits: 8, maximumFractionDigits: 8 }),
                ema26: lastItem.ema_26.toLocaleString('en-US', { minimumFractionDigits: 8, maximumFractionDigits: 8 }),
                ema50: lastItem.ema_50.toLocaleString('en-US', { minimumFractionDigits: 8, maximumFractionDigits: 8 }),
                ema90: lastItem.ema_90.toLocaleString('en-US', { minimumFractionDigits: 8, maximumFractionDigits: 8 }),
                emaTrend: lastItem.EMA_final_trend,
            };
        } else if (endpoint.url === '/sma-v2') {
            // For other endpoints, parse the data as needed from the last item
            const lastItem = data[data.length - 1];
            result = {
                sma5: lastItem.sma_5.toLocaleString('en-US', { minimumFractionDigits: 8, maximumFractionDigits: 8 }),
                sma10: lastItem.sma_10.toLocaleString('en-US', { minimumFractionDigits: 8, maximumFractionDigits: 8 }),
            };
        } else if (endpoint.url === '/wma-v2') {
            // For other endpoints, parse the data as needed from the last item
            const lastItem = data[data.length - 1];
            result = {
                'wma 5': lastItem.wma_5.toLocaleString('en-US', { minimumFractionDigits: 8, maximumFractionDigits: 8 }),
                wma10: lastItem.wma_10.toLocaleString('en-US', { minimumFractionDigits: 8, maximumFractionDigits: 8 }),
            };
        } else if (endpoint.url === '/dema-v2') {
            // For other endpoints, parse the data as needed from the last item
            const lastItem = data[data.length - 1];
            result = {
                dema5: lastItem.dema_5.toLocaleString('en-US', { minimumFractionDigits: 8, maximumFractionDigits: 8 }),
                dema10: lastItem.dema_10.toLocaleString('en-US', { minimumFractionDigits: 8, maximumFractionDigits: 8 }),
            };
        } else if (endpoint.url === '/tema-v2') {
            // For other endpoints, parse the data as needed from the last item
            const lastItem = data[data.length - 1];
            result = {
                tema5: lastItem.tema_5.toLocaleString('en-US', { minimumFractionDigits: 8, maximumFractionDigits: 8 }),
                tema10: lastItem.tema_10.toLocaleString('en-US', { minimumFractionDigits: 8, maximumFractionDigits: 8 }),
            };
        } else if (endpoint.url === '/kama-v2') {
            // For other endpoints, parse the data as needed from the last item
            const lastItem = data[data.length - 1];
            result = {
                kama5: lastItem.kama_5.toLocaleString('en-US', { minimumFractionDigits: 8, maximumFractionDigits: 8 }),
                kama10: lastItem.kama_10.toLocaleString('en-US', { minimumFractionDigits: 8, maximumFractionDigits: 8 }),
            };
        } else if (endpoint.url === '/vwap-v2') {
            // For other endpoints, parse the data as needed from the last item
            const lastItem = data[data.length - 1];
            result = {
                vwap: lastItem.vwap.toLocaleString('en-US', { minimumFractionDigits: 8, maximumFractionDigits: 8 }),
            };
        } else if (endpoint.url === '/candles-type') {
            const lastItem = data[data.length - 1];
            result = {
                CandleType: lastItem.Candle_Type,
                Description: lastItem.Description,
                candle_sentiment: lastItem.candle_sentiment,
            };
        }

        return result;
    };

    const fetchEndpoints = async () => {
        if (!auth.token) {
            setError('You must be logged in to fetch data.');
            return;
        }

        setModalOpen(true);
        setIsLoading(true);
        try {
            const headers = { Authorization: `Bearer ${auth.token}` };
            const dataPromises = endpoints.map((endpoint) =>
                axios
                    .get(`${baseUrl}${endpoint.url}`, { headers, params })
                    .then((response) => {
                        // console.log(`Response from ${endpoint.url}:`, response.data); 
                        return {
                            endpoint: endpoint.url,
                            data: parseData(endpoint.url, response.data),
                        };
                    })
                    .catch((error) => {
                        console.error(`Error fetching data from ${endpoint.url}:`, error);
                        return {
                            endpoint: endpoint.url,
                            data: 'Failed to fetch data',
                            error: true,
                        };
                    })
            );

            const results = await Promise.all(dataPromises);
            setEndpointData(results);
        } catch (err) {
            console.error('Error during fetch:', err);
            setError(err.message);
        }
        setIsLoading(false);
        setModalOpen(false);
    };

    useEffect(() => {
        if (auth.token && params.symbol && params.interval && params.limit) {
            fetchEndpoints();
        }
        // eslint-disable-next-line
    }, [auth.token, params]); // Dependency on auth.token and params

    return (
        <div className='main-container'>
            <FullScreenModal isOpen={isModalOpen} logo={logo} />
            {auth.token ? (
                <>
                    <h3>Introducing our latest feature: quick access to technical analysis data and detailed candle descriptions! When you navigate to the Market Analysis page, you'll find these valuable insights readily available.</h3>
                    <h4>Remember, if you're looking for the ISR calculator, you can still access it from the navigation menu. Enjoy enhanced trading insights with ease!</h4>    

                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '40px' }}>
                        <div >
                            <Select
                                name="symbol"
                                options={symbolOptions}
                                onChange={handleInputChange}
                                defaultValue={symbolOptions.find((option) => option.value === params.symbol)}
                                placeholder="Select Symbol"
                                isSearchable
                                styles={{ width: '30%' }} // Adjust the width of the symbol dropdown
                            />
                        </div>
                        <div style={{ marginLeft: '10px' }}>
                            <Select
                                name="interval"
                                options={intervalOptions}
                                onChange={handleInputChange}
                                defaultValue={intervalOptions.find((option) => option.value === params.interval)}
                                placeholder="Select Interval"
                            />
                        </div>
                        <div style={{ marginLeft: '10px' }}>
                            <Select
                                name="limit"
                                options={limitOptions}
                                onChange={handleInputChange}
                                defaultValue={limitOptions.find((option) => option.value === params.limit)}
                                placeholder="Select Limit"
                            />
                        </div>
                    </div>
                    {/* <button onClick={fetchEndpoints} disabled={isLoading}>
                        Fetch Data
                    </button> */}
                    {isLoading && <p>Loading...</p>}
                    {error && <p>Error: {error}</p>}
                </>
            ) : (
                <p>You must log in to see the data.</p>
            )}
            <TradingViewChart symbol={params.symbol} />



                <div className='indicator-container'>
                    {/* Display endpoint data independently */}
                    {endpointData.map((item, index) => (
                        <div className='indicator' key={index}>
                            <h3>Endpoint: {item.endpoint}</h3>
                            {/* Iterate over keys of item.data and render each variable independently */}
                            {Object.entries(item.data).map(([key, value]) => (
                                <div className='data' key={key}>
                                    <strong>{key}:</strong> {value}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>

        </div>
    );
};

export default CoinTable;
