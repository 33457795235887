import React, { useEffect, useContext } from 'react';
import jstz from 'jstz';
import './TradingViewChart.css';
import { AuthContext } from '../AuthContext';

const CONTAINER_ID = "tradingview_chart";

function TradingViewChart({ symbol }) {
  const { pair } = useContext(AuthContext);

  useEffect(() => {
    const initCandleChart = () => {
      const cleanedSymbol = symbol.replace('-', '');
      let params = {
        width: '100%',
        height: '475',
        symbol: `KUCOIN:${cleanedSymbol}`, // Use the passed symbol prop or default to pair
        interval: "1D",
        timezone: "Etc/UTC",
        theme: "light",
        style: "1",
        locale: "en",
        toolbar_bg: "#1C212B",
        show_popup_button: true,
        popup_width: 1250,
        popup_height: 1000,
        enable_publishing: false,
        hide_side_toolbar: false,
        container_id: CONTAINER_ID
      };

      let timezone = jstz.determine();
      if (timezone) {
        params.timezone = timezone.name();
      }

      const existingScript = document.querySelector(`script[src="https://s3.tradingview.com/tv.js"]`);
      if (existingScript) {
        new window.TradingView.widget(params);
      } else {
        const script = document.createElement('script');
        script.src = "https://s3.tradingview.com/tv.js";
        script.async = true;
        script.onload = () => {
          new window.TradingView.widget(params);
        };
        document.body.appendChild(script);
      }
    };

    initCandleChart();

    return () => {
      const script = document.querySelector(`script[src="https://s3.tradingview.com/tv.js"]`);
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, [symbol, pair]); // Include symbol and pair in the dependency array

  return (
    <>
      <div>
        <div className='T-container' id={CONTAINER_ID}></div>
      </div>
    </>
  );
}

export default TradingViewChart;
